.cloud-wc {
  margin: 2rem 0;
  display: flex;
  border-left: 3px solid #fed23a;
  background-color: #3d3d3d;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.16px;
  max-width: 34rem;
  align-items: flex-start;
  opacity: 0.9;
  &-icon {
    margin: 1rem 1rem 0 1rem;
  }
  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    &-header {
      font-size: 18px;
      line-height: 28px;
      margin: 0;
      padding: 4px 0;
      color: #f3f3f3;
      font-weight: 300;
      letter-spacing: 0.32px;
    }
    &-text {
      line-height: 17px;
      color: #f3f3f3;
      font-size: 13px;
    }
    &-btn {
      height: 1.5rem;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
      cursor: pointer;
      background-color: #606060;
      padding: 8px 13px;
      color: #fff;
      font-weight: 300;
      max-width: 9rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      letter-spacing: 0.16px;
      text-decoration: none;
    }
  }
}
