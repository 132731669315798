//THIS CLASS HAS BEEN MODIFIED FOR THE FILTER PURPOSE
//If we intend to reuse the 'manage' class elsewhere,
.manage {
  position: absolute;
  z-index: 4;
  display: flex;
  flex-direction: column;
  width: calc(100% - 24px);
  margin-top: 0;
  margin-left: 0;
  padding: 12px;
  background-color: #3d3d3d;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.25);
}
.manage-overlay {
  position: fixed;
  height: 100%;
  width: 383px;
  right: 0;
  top: 0;
  background: #171717;
  opacity: 0.5;
  z-index: 4;
}

.filter-width {
  width: calc(100% - 34px);
}
