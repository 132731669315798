.aws-action-node {
  &-output {
    &-label {
      margin-top: 10px;
      font-size: 12px;
    }
    &-selector {
      font-size: 11px;
      word-break: break-all;
      overflow: hidden;
      background: #353535;
      padding: 8px;
      &-label {
        margin-top: 16px;
        font-size: 12px;
      }
      &-panel {
        height: 600px;
      }
    }
  }
}
