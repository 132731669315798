@import "../../scss/variables.scss";

.runbooks-grid {
  padding-top: 48px;
  display: grid;
  margin-bottom: 32px;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  align-content: start;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-columns: repeat(auto-fit, minmax(320px, max-content));
  cursor: default;
}

.runbook-item {
  height: 140px;
  position: relative;
  max-width: 420px;
  min-width: 320px;
  display: flex;
  background-color: #282828;
  &:hover {
    filter: brightness(130%);
  }
}

.runbook-item__container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 420px;
  min-width: 320px;
}

.runbook-item__meta {
  padding: 15px;
  position: relative;
  padding-right: 54px;

  h2 {
    margin-top: 0px;
    font-weight: 300;
    word-break: break-all;
    overflow: hidden;
    max-height: 44px;
  }
}

.runbook-item__description {
  color: #bebebe;
  font-weight: 300;
  font-size: 13px;
  max-width: 270px;
  height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.runbook-item__title {
  margin-bottom: 6px;
  color: #fff;
  font-size: 17px;
  line-height: 22px;
}

.runbook-thumbnail__wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 56%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid transparent;
  background-color: #363636;
  -webkit-transition: border-color 200ms ease;
  transition: border-color 200ms ease;
}

.runbook-thumbnail {
  width: 100%;
  height: 100%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-image: url("runbooks-components/runbooks-panels/diagram-preview.svg");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-filter: invert(15%);
  filter: invert(15%);
}

.runbook-tabs > .tabs-wrap {
  border-bottom: 2px solid #252525;
}

.runbook-item__author {
  color: #bebebe;
  font-size: $fontSizeMediumLarge;
  font-weight: 300;
}

.fill-text {
  visibility: hidden;
  max-height: 1px;
  overflow: hidden;
}

.runbook-item__tag-wrap {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 16px;
  margin-left: 15px;
  margin-top: 8px;
  margin-right: 16px;
  z-index: 1;
  height: 23px;
  overflow: hidden;
}

.tag {
  display: inline-block;
  height: 1.5rem;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-radius: 0.9375rem;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.32px;

  .tag_primary {
    height: 23px;
    margin-right: 8px;
    margin-left: 0rem;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    background-color: #3d3d3d;
    color: white;
    font-size: $fontSizeMedium;
    line-height: 12px;
    font-weight: 300;
    letter-spacing: 0.16px;
  }

  &__inner-wrap {
    color: white;
    font-size: $fontSizeMedium;
    line-height: 12px;
    font-weight: 300;
    letter-spacing: 0.16px;
    margin-top: 5px;
  }
}

.tag_primary-light {
  height: 23px;
  margin-right: 8px;
  margin-left: 0rem;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: #3d3d3d;
  color: hsla(0, 0%, 95.3%, 0.7);
  font-size: $fontSizeMedium;
  line-height: 12px;
  font-weight: 300;
  letter-spacing: 0.16px;
}

.label-inline {
  & > div {
    display: inline-block;
  }
}

.label-left {
  margin-right: 5px;
  display: inline-block;
}

.label-right {
  margin-right: 5px;
  display: inline-block;
}

.label-top {
  color: hsla(0, 0%, 95.3%, 0.7);
  font-size: 11;
  font-weight: 300;
}

.welcome-dialog {
  display: flex;
  flex-direction: column;
  &__img-wrap {
    padding: 2rem;
    background: #6b6b6b;
    display: flex;
    justify-content: center;
  }
  img {
    width: 100%;
  }
  span {
    margin-top: 1rem;
  }
}

.welcome-content {
  margin-bottom: 1rem;
}

.welcome-container {
  max-width: 558px;
}

.reset-search {
  color: #5ca8ff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: #7cb9ff;
  }
}
