.gr-form {
  &-fields {
    overflow-y: auto;
  }
  &-footer {
    height: 3.5rem;
  }
}

.text-area {
  width: 100%;
  margin-bottom: 0px;
  border-color: transparent transparent #8c8c8c;
  border-radius: 0px;
  background-color: #3d3d3d;
  box-shadow: inset 0 0 0 2px transparent;
  transition: all 70ms ease;
  color: #f3f3f3;
  font-weight: 300;
  &:hover {
    background-color: #4c4c4c;
  }
  &:focus {
    border-bottom-color: transparent;
    box-shadow: inset 0 0 0 2px #f3f3f3;
  }
  &-error {
    border-bottom-color: #fa4d56;
  }
}
