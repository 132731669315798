/* TITLES, HEADERS */
.title-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 1px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 40px;
  padding: 0 1rem;
}

.page-title {
  margin-bottom: 0px;
  color: #fff;
  font-size: 1.875rem !important;
  line-height: 2.675rem;
  letter-spacing: 0.32px;

  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: 400;
}

.flex-centered {
  display: flex;
  justify-content: center;
  justify-items: center;
}

.centered {
  justify-content: center;
  text-align: center;
  justify-items: center;
}

.vertical-middle {
  justify-items: center;
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.link-wrapper {
  text-decoration: none;
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

a {
  background-color: transparent;
}

.link-white {
  color: #f3f3f3;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.link-light-blue {
  color: #5ca8ff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.header-light-blue {
  color: #5ca8ff;
}

/* specific font sizes */
.font-10 {
  font-size: $fontSizeSmall;
}

.font-11 {
  font-size: $fontSizeMediumSmall;
}

/* MARGINS, PADDING, FIXED WIDTHS */
.margin-left-5 {
  margin-left: 5px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-16 {
  margin-left: 16px;
}

.margins-20 {
  margin: 20px;
}
.margins-30 {
  margin: 30px;
}

.margin-top-5b {
  margin-top: 10px;
  padding-left: 12px;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-left {
  &-10 {
    margin-left: 10px;
  }
  &-20 {
    margin-left: 20px;
  }
  &-24 {
    margin-left: 24px;
  }
}
.margin-right {
  &-20 {
    margin-right: 20px;
  }
}
.width-150 {
  max-width: 150px;
  width: 150px;
}
.width-200 {
  max-width: 200px;
  width: 200px;
}
.width-250 {
  width: 250px;
}

.width-20-pct {
  width: 20%;
}
.width-80-pct {
  width: 80%;
}

.width-full {
  box-sizing: border-box;
  width: 100%;
}

.div-padding-10 > div {
  padding: 10px;
  box-sizing: border-box;
}
.div-padding-10b > div {
  padding: 10px;
  padding-left: 2px;
  box-sizing: border-box;
}

.padding-0 {
  padding-left: 0px !important;
}

.padding-5 {
  padding: 5px;
}

.padding-10 {
  padding: 10px;
}

.div-right-label {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.div-table {
  display: table;
}
.div-row {
  display: table-row;
}
.div-cell {
  display: table-cell;
  padding: 5px;
}

/* FORM ELEMENTS */
.label {
  display: inline-block;
  margin-bottom: 0.5rem;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #bebebe;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 300;
  letter-spacing: 0.32px;
  cursor: default;
}

/* DIVS */
.oval {
  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
    align-items: center;
    margin-bottom: 10px;
  }
  &-item {
    border-style: none;
    border-width: 1px;
    border-color: #282828;
    background-color: #535353;
    color: #f3f3f3;
    font-size: $fontSizeMediumSmall;
    height: 1.5rem;
    border-radius: 0.9375rem;
    text-align: center;
    padding-left: 8px;
    padding-right: 2px;
    margin-right: 5px;
    margin-top: 5px;
    cursor: pointer;
    &-inner-wrap {
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
  &-x {
    margin-left: 10px;
    border-radius: 8px;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.25rem;
    padding: 0.125rem;
    flex: 0 0 auto;
    background-image: url("../../assets/images/icons/icon-close.svg");
    background-position: 50% 50%;
    background-size: 8px;
    background-repeat: no-repeat;
    opacity: 0.5;
    transition: all 90ms ease;
    border-radius: 100%;
    &:hover {
      background-color: #353535;
    }
  }
}

/* GRIDS */
.grid-3-columns {
  display: grid;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr 3fr 1fr;
  column-gap: 2px;

  & > div {
    height: 100%;
  }
}

.grid-2-columns {
  display: grid;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr 4fr;
  column-gap: 2px;

  & > div {
    height: 100%;
  }
}

/* FORMS, BUTTONS, INPUTS */
.button {
  &-delete {
    background: #fb4b53;
    color: #f3f3f3;
    &:hover {
      background: #f8353e;
    }
  }
  &-grey {
    background: rgb(61, 61, 61);
    &:hover {
      background: #444;
    }
  }
  &-error {
    border: 1px solid #ff5b5b !important;
  }
}

.text-input {
  &-small {
    height: 24px;
    border-style: solid;
    border-width: 1px;
    border-color: #303030;
    background-color: #555;
    transition: all 70ms ease;
    color: #f3f3f3;
    font-size: $fontSizeMedium;
    line-height: 18px;
    margin-top: 10px;
    width: 210px;
    padding: 3px;
  }
}

/* miscellaneous */
.found-text {
  color: yellow;
}
.paragraph {
  color: hsla(0, 0%, 95.3%, 0.9);
  font-size: $fontSizeMedium;
  line-height: 18px;
  text-align: left;
  padding: 14px;
  margin-bottom: 14px;
}
.paragraph-editor {
  color: hsla(0, 0%, 95.3%, 0.9);
  font-size: $fontSizeSmall;
  line-height: 18px;
  text-align: left;
  padding: 12px;
  margin-bottom: 14px;
}

.tbl-header {
  box-sizing: border-box;
  display: flex;
  height: 34px;
  align-items: center;
  background-color: #535353;
  padding-left: 12px;
}

.tbl-row {
  box-sizing: border-box;
  display: flex;
  padding-left: 12px;
}

.text-area {
  height: 200px;
  min-height: 200px;
  margin-bottom: 0px;
  border-style: solid;
  border-width: 1px;
  border-color: #2e2e2e;
  background-color: #555;
  -webkit-transition: all 70ms ease;
  transition: all 70ms ease;
  color: #f3f3f3;
  font-size: $fontSizeMedium;
  line-height: 18px;
  width: 100%;
}
.compact-text-area {
  height: 70px;
  min-height: 70px;
  margin-bottom: 0px;
  border-style: solid;
  border-width: 1px;
  border-color: #2e2e2e;
  background-color: #555;
  -webkit-transition: all 70ms ease;
  transition: all 70ms ease;
  color: #f3f3f3;
  font-size: $fontSizeMedium;
  line-height: 18px;
  width: 100%;
}
.text-small {
  font-size: $fontSizeMediumSmall;
}

.input-label {
  vertical-align: top;
  margin-top: 8px;
  width: 70px;
  margin-left: 10px;
}

*.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
     Introduced in IE 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
  -ms-user-select: none;
  user-select: none;
}

.no-background {
  background: none;
}

.no-list-style {
  list-style: none;
}
