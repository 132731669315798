.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  &-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    height: 2rem;
    width: 4rem;
    color: #f3f3f3;
    border-style: solid;
    border-width: 1px;
    border-color: #303030;
    cursor: pointer;
    opacity: 1;
    font-size: 12px;
    line-height: 12px;
    &-cancel {
      background-color: #7a7a7a;
      margin-right: 0.5rem;
      width: auto;
      min-width: 4rem;
      &:hover {
        filter: brightness(1.1);
      }
    }
    &-save {
      background-color: #6300ff;
      width: auto;
      min-width: 4rem;
      &:hover {
        filter: brightness(1.1);
      }
    }
    &__danger {
      background-color: #fb4b53;
      &:hover {
        filter: brightness(1.1);
      }
    }
  }
}
