.input {
  &-wrap {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    &-label {
      color: rgba(243, 243, 243, 0.7);
      font-size: 0.75rem;
    }
    &-input-container {
      text-overflow: ellipsis;
      background-color: #363636;
      color: #f3f3f3;
      height: 1.8rem;
      font-size: 0.75rem;
      padding-left: 10px;
      padding-right: 4rem;
      margin-top: 0.5rem;
      border: 1px solid #2b2b2b;
    }
    &-icon {
      width: 32px;
      height: 31px;
      bottom: 1px;
      padding: 6px 0 0 8px;
      position: absolute;
      background: #424242;
      border-left: 1px solid #2b2b2b;
      &:hover {
        filter: brightness(1.2);
      }
      &__copy {
        right: 1px;
      }
      &__eye {
        right: 33px;
      }
    }
  }
}

.eye-icon {
  z-index: 1;
  cursor: pointer;
  margin-left: -0.3rem;
  fill: #bebebe;
  height: 18px;
}
