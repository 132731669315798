@import "base/normalize.css";
@import "components/button.scss";
@import "components/data-table.scss";
@import "components/forms.scss";
@import "components/logo.scss";
@import "components/tag.scss";
@import "components/node.scss";
@import "components/runbookexecutestatus.scss";
@import "components/runbookdetailmodal.scss";
@import "components/deleterunbookmodal.scss";
@import "components/ui.scss";
@import "containers/leftMenu.scss";
@import "containers/headerNav.scss";
@import "containers/canvasMenu.scss";
@import "containers/login.scss";
@import "./variables.scss";

/*added here to prevent ReactSelect.scss precedence over common class scss*/
@import "@components/ui/React-Select/ReactSelect.scss";

/* tags */
body {
  overscroll-behavior: none;
  overflow-y: hidden;
  overflow-x: hidden;
  background: $bodyBackground;
  font-family: $fontFamily;
  color: $bodyFontColor;
  font-size: $bodyFontSize;
}

h4 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: $h4FontColor;
  font-size: $fontSizeLarge;
  line-height: 24px;
  font-weight: 300;
  letter-spacing: 0.32px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: transparent;
}

::-webkit-scrollbar-track-piece {
  background-color: transparent;
  -webkit-border-radius: 6px;
}

/* Corner color */
::-webkit-scrollbar-corner {
  background: #303030;
}

.main {
  height: 100vh;
  display: flex;
  &-left {
    height: 100%;
    width: 16rem;
    flex-shrink: 0;
    background-color: $secondaryBackground;
    overflow: hidden;
  }
  &-right {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 2rem 2.5rem;
  }
}

.pointer {
  cursor: pointer;
}

.default-cursor {
  cursor: default;
}

.hidden {
  display: none !important;
}

/* animations */

.fade-in {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  &-fast {
    animation: fadeInAnimation ease 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}

.fade-fast {
  animation: fadeOutAnimation ease 10.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.fade-slow {
  animation: fadeOutAnimation ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}

.scale-out {
  animation: scaleOut 0.2s ease-in-out 0s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.fade-select-none {
  opacity: 0;
  animation: fadeInAnimation ease 0.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
}

.slide-out-from-right {
  transform-origin: 100% 0%;
  animation: slideOutFromRight 0.3s ease-in-out 0s 1 reverse running;
}

.slide-out-from-left {
  transform-origin: 0% 100%;
  animation: slideOutFromLeft 0.5s ease-in-out 0s 1 reverse running;
}

.slide-in-to-right {
  transform-origin: 100% 0%;
  animation: slideInToRight 0.3s ease-in-out 1s 1 reverse running;
}

.fine-print {
  font-size: $fontSizeMedium;
}

.slack-editor {
  pre {
    background-color: dimgray !important;
  }
}

.add-slack-variable {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 30px;
  height: 30px;
}

.add-slackvariable-button {
  display: flex;
  width: auto;
  justify-content: center;
  max-width: none !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-size: 11px;
  margin-top: 30px;
  height: 28px;
  line-height: 18px;
  margin-bottom: 16px;
  border: 1px solid #303030;
  &:focus {
    border: 1px solid #303030;
    box-shadow: none;
  }
  &:hover {
    border: 1px solid #303030;
  }
  &:active {
    background-color: #606060 !important;
  }
}

.save-slackvariable-button {
  display: flex;
  width: auto;
  justify-content: center;
  max-width: none !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-size: 11px;
  margin-top: 30px;
  height: 28px;
  line-height: 18px;
  margin-bottom: 16px;
  border: 1px solid #3f00a2;
  &:focus {
    border: 1px solid #3f00a2;
    box-shadow: none;
  }
  &:hover {
    border: 1px solid #3f00a2;
  }
}

.insert-dynamic-variable-button {
  position: relative;
  width: 100%;
  padding: 0px;
  background: #555555;
  margin-top: -1px;
  border-left: 1px solid #171717;
  border-right: 1px solid #171717;
  border-bottom: 1px solid #171717;
  button {
    background-color: #6d6c6f;
    border-top: 1px solid #171717;
    border-right: 1px solid #171717;
    border-bottom: 1px solid transparent;
    border-left: 1px solid transparent;
    color: white;
    height: 28px;
    font-size: 11px;
    line-height: 19px;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    &:hover {
      background-color: #606060;
    }
  }
}

.slack-dynamic-variable-list {
  position: absolute;
  width: 100%;
  z-index: 100;
  background-color: #232324;
  ul {
    padding: 0px;
    padding-left: 6px;
    color: #f3f3f3;
    font-size: 12px;
    margin: 0px;
    li {
      padding: 5px;
    }
  }
}

.ql-editing {
  left: 0 !important;
}

.d-inline-flex {
  display: inline-flex;
}

@keyframes slideOutFromRight {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
@keyframes slideOutFromLeft {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

@keyframes slideInToRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes scaleOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*
  THIS IS THE GENERATED FILE THAT IS PRODUCED USING THE SIMPLE LOOP SHOWN ABOVE
*/

.mt-2 {
  margin-top: 2px;
}

.mt-5 {
  margin-top: 5px;
}

.pt-5 {
  padding-top: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.pl-5 {
  padding-left: 14px;
  font-weight: 300;
}

.mr-5 {
  margin-right: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.p-10 {
  padding: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.pt-15 {
  padding-top: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.pl-15 {
  padding-left: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.pt-25 {
  padding-top: 25px;
}

.mt-25 {
  margin-top: 25px;
}

.pb-25 {
  padding-bottom: 25px;
}

.ml-25 {
  margin-left: 25px;
}

.pl-25 {
  padding-left: 25px;
}

.mr-25 {
  margin-right: 25px;
}

.pr-25 {
  padding-right: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.pt-30 {
  padding-top: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.ml-30 {
  margin-left: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.pt-35 {
  padding-top: 35px;
}

.mb-35 {
  margin-bottom: 35px;
}

.pb-35 {
  padding-bottom: 35px;
}

.ml-35 {
  margin-left: 35px;
}

.pl-35 {
  padding-left: 35px;
}

.mr-35 {
  margin-right: 35px;
}

.pr-35 {
  padding-right: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.pl-40 {
  padding-left: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.pr-40 {
  padding-right: 40px;
}

.at-40 {
  top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.pt-45 {
  padding-top: 45px;
}

.mb-45 {
  margin-bottom: 45px;
}

.pb-45 {
  padding-bottom: 45px;
}

.ml-45 {
  margin-left: 45px;
}

.pl-45 {
  padding-left: 45px;
}

.mr-45 {
  margin-right: 45px;
}

.pr-45 {
  padding-right: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.pt-50 {
  padding-top: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.ml-50 {
  margin-left: 50px;
}

.pl-50 {
  padding-left: 50px;
}

.mr-50 {
  margin-right: 50px;
}

.pr-50 {
  padding-right: 50px;
}

.mt-100 {
  margin-top: 100px;
}

.link-underline {
  text-decoration: underline !important;
}

.signout-section {
  &:hover {
    background-color: $tertiaryBackground;
  }
}

.breadcrumb-text {
  color: white;
  &:hover,
  &:link,
  &:visited {
    text-decoration: underline;
  }
}

.no-error {
  border-bottom: 1px solid #8c8c8c;
}
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pt-0 {
  padding-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.strong-span {
  font-weight: 500;
}

span.default-version {
  background-color: #656565;
  color: #f3f3f3;
  padding: 6px 9px;
  font-size: 8px;
  display: inline-block;
  border-radius: 2px;
  text-transform: uppercase;
  margin-left: 10px;
}

.styled-check-box {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    opacity: 1;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  & + label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 16px;
    height: 16px;
    background: transparent;
    border: 1px solid #6f6f6f;
  }

  &:hover + label:before {
    border: 1px solid #fff;
  }

  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  &:checked + label {
    opacity: 1;
  }

  &:checked + label:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 9px;
    background: #282828;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 #282828, 4px 0 0 #282828, 4px -2px 0 #282828,
      4px -4px 0 #282828, 4px -6px 0 #282828, 4px -8px 0 #282828;
    transform: rotate(45deg);
  }
}

.styled-check-box:hover + label:before {
  background: transparent;
}

.styled-check-box:checked + label:after {
  left: 4px;
  top: 16px;
}

.styled-check-box:checked + label:before {
  background: #f3f3f3;
  border: 1px solid #f3f3f3;
}

.no-pointer {
  pointer-events: none;
  cursor: not-allowed;
}
.d-flex {
  display: flex;
  &__sb {
    justify-content: space-between;
  }
}

.w-auto {
  width: auto;
}

.align-items-baseline {
  align-items: baseline;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}

.scroll-x-auto {
  overflow-x: auto;
}
