.result {
  display: flex;
  align-items: center;
  font-size: 12px;
  border-color: #2b2b2b;
  background-color: #363636;
  color: #f3f3f3;
  line-height: 20px;
  padding: 16px;
  margin-bottom: 8px;
  overflow: hidden;
  word-break: break-all;
}
