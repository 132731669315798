.slide-panel {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 999;
  width: 36rem;
  height: 100vh;
  padding: 16px;
  padding-right: 24px;
  padding-left: 24px;
  background-color: #282828;

  &:hover {
    -webkit-transition: -webkit-transform 0.5s;
    transition: all 0.5s ease-in-out;
    translate: translate(30px, 30px);
  }
}
.slide-panel.side-panel__user-mgmt {
  width: 28rem;
}

.slide-panel__header {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.slide-panel__close {
  background: none;
  border: none;
  color: #e9e9e9;
  cursor: pointer;
}
.side-panel__container {
  margin-top: 1rem;
  width: 100%;
  height: 80vh;
  padding-right: 1rem;
  padding-bottom: 2rem;
  padding-top: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.side-panel__container > form > .form-item {
  margin-bottom: 1.5rem;
}
.side-panel__container > form > .account-info__wrap {
  margin-bottom: 1.5rem;
}
.side-panel__inner-wrap {
  width: 100%;
  margin-top: 32px;
  padding-right: 16px;
  padding-left: 16px;
}
.form-item__margin-bottom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
}
.form-item__mb-l {
  margin-bottom: 1.5rem;
}
.form-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
}

.form-field-label {
  display: flex;
  justify-content: space-between;
  width: 100%;
  label {
    width: -webkit-fill-available;
  }
}

.side-panel__footer {
  position: absolute;
  right: 0%;
  bottom: 0%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 3.5rem;

  flex: 0 0 auto;
  background-color: #dcdcdc;
}
.btn.modal-footer-btn {
  height: 4rem;
  max-width: none;
  margin: 0rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.btn.btn-secondary {
  //height: 40px;
  min-height: 0rem;
  //margin-right: 1rem;
  padding: 8px 61px 8px 13px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-width: 2px;
  background-color: #6f6f6f;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  color: #fff;
  font-weight: 300;
}
.btn-secondary {
  padding-left: 16px;
}
.btn {
  position: relative;
  display: inline-block;
  max-width: 20rem;
  min-height: 3rem;
  margin: 0rem;
  padding: 11px 60px 11px 12px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 3px solid transparent;
  box-shadow: inset 0 0 0 1px transparent;
  -webkit-transition: all 70ms ease;
  transition: all 70ms ease;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: #171717;
  line-height: 1.25rem;
  letter-spacing: 0.16px;
  text-decoration: none;
  cursor: pointer;
}

input.error {
  border-color: #fa4d56;
}
textarea.error {
  border-bottom-color: #fa4d56;
}
.input-feedback {
  color: #ff8389;
  margin-top: 0.25rem;
  font-size: 13px;
}

.label-name {
  margin-bottom: 0.5rem;
  color: #bebebe;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 300;
  letter-spacing: 0.32px;
}

.json-detail {
  position: "static";
  &-icon {
    position: "relative";
    top: 0;
    right: 0;
  }
}

.json-view-container {
  position: "absolute";
  background-color: "#3d3d3d";
  padding: 10px;
  top: 23px;
  right: 0%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 1.5rem;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1rem;
  width: 1rem;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #3dbb61;
}

input:focus + .slider {
  box-shadow: 0 0 1px #3dbb61;
}

input:checked + .slider:before {
  -webkit-transform: translateX(1rem);
  -ms-transform: translateX(1rem);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 2rem;
}

.slider.round:before {
  border-radius: 50%;
}

.slack-notifications {
  &-toggle {
    display: flex;
    justify-content: space-between;
  }
}

.formik-field-error {
  border-bottom-color: #fa4d56 !important;
}

.input-eye-icon {
  z-index: 1;
  cursor: pointer;
  margin-left: -2rem;
  margin-right: 10px;
  margin-top: 1rem;
  fill: #8c8c8c;
  height: 18px;
  margin-top: 16px;
}

.field-wrapper {
  display: flex;
  width: 100%;
}

.enable-notifications {
  &-toggle {
    display: flex;
    justify-content: space-between;
  }
}
