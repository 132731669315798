@import "../../scss/variables.scss";

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $canvasPrimaryBackground;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $canvasPrimaryAccent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $webkitScrollHover;
}

/* Corner color */
::-webkit-scrollbar-corner {
  background: #303030;
}

.runbook {
  &-title {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: 40px;
    padding-right: 16px;
    padding-left: 16px;
    align-items: center;
    border-right: 1px solid $canvasBorder1;
    border-left: 1px solid $canvasBorder1;
    transition: all 70ms ease;
    color: $secondaryFontColor;
    font-size: $fontSizeMediumLarge;
    font-weight: 300;
    letter-spacing: 0.16px;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      background-color: $tertiaryBackground;
    }
  }
}

.runbook-title > .link-underline {
  padding-right: 8px;
}

.d-flex {
  display: flex;
  &-sb {
    display: flex;
    justify-content: space-between;
  }
}

.editor {
  box-sizing: border-box;

  &-right-panel-resize {
    height: 100%;
    width: 1%;
    max-width: 3px;
    position: absolute;
    left: 0px;
    z-index: 100;
    cursor: ew-resize;
    &:active {
      background: yellow;
    }
  }

  /* editor-is-fetching */
  &-is-fetching {
    margin-top: 5px;
    font-style: italic;
    color: $canvasPrimaryAccent;
  }

  /* editor-gear, editor-gear-icon */
  &-gear {
    &-icon {
      display: -webkit-box;
      width: 20px;
      height: 20px;
      flex: 0 0 auto;
      background-image: url("../../assets/images/icons/gear-icon__filled.svg");
      background-position: 50% 50%;
      background-size: 12px 12px;
      background-repeat: no-repeat;
      opacity: 0.7;
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
      cursor: pointer;
      &-wrap {
        position: absolute;
        top: 14px;
        right: 22px;
        display: flex;
        width: 16px;
        height: 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      &:hover {
        opacity: 1;
        background-color: #555;
      }
    }
  }
  &-page-wrap {
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
  }

  &-dropdown {
    display: flex;
    padding: 0px 12px;
    justify-content: flex-start;
    border-style: solid;
    border-width: 1px;
    border-color: $canvasPrimaryBackground;
    background-color: $tertiaryAccentColor;
    transition: all 70ms ease;
    color: $secondaryFontColor;
    font-size: $fontSizeMedium;
    font-weight: 300;
    box-sizing: border-box;
    top: 2px;

    /* editor-dropdown-open */
    &-container {
      position: absolute;
      display: flex;
      flex-direction: column;
      top: 32px;
      right: 16px;
      min-width: 240px;
      border-style: solid;
      border-width: 1px;
      border-color: $canvasPrimaryBackground;
      background-color: $tertiaryAccentColor;
      box-shadow: 5px 0 10px 0 rgba(0, 0, 0, 0.15);
      color: $secondaryFontColor;
      font-size: $fontSizeMedium;
      font-weight: 300;
      max-height: 120px;
      align-content: center;
      z-index: 200;
      padding: 0px;
      padding-bottom: 4px;
      padding-top: 4px;
    }
    & .label:hover {
      color: #f3f3f3;
    }
    & .label {
      cursor: pointer;
    }
    &-link {
      position: relative;
      display: flex;
      align-content: center;
      transition: all 70ms ease;
      color: $secondaryFontColor;
      font-size: $fontSizeMedium;
      font-weight: 300;
      letter-spacing: 0.16px;
      cursor: pointer;
      width: 100%;
      padding-top: 5px;
      padding-left: 8px;
      height: 28px;
      &:hover {
        background-color: #555;
      }
      &:last-child {
        border-bottom: none;
      }
    }

    &-mask {
      position: relative;
      top: -2px;
      width: 100%;
      height: 4px;
      background-color: $tertiaryAccentColor;
    }

    &-section-title {
      display: flex;
      height: 26px;
      padding-left: 8px;
      align-items: center;
      background-color: $accordionPrimaryBackground;
      color: $accordionAccentColor;
      font-size: $fontSizeSmall;
      font-weight: 400;
      letter-spacing: 0.16px;
      text-transform: uppercase;
    }
  }

  /* END OF editor-dropdown */

  &-node-name {
    font-size: $fontSizeHeadingSmall;
    font-weight: 400;
    padding-left: 12px;
    color: #5ca8ff;
  }

  &-menu {
    &-tab {
      &-content {
        &-panel {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
        &-area {
          overflow-y: scroll;
          overflow-x: hidden;
          height: 100%;
          padding-bottom: 88px;
        }
        &-container {
          position: absolute;
          z-index: 5;
          top: 40px;
          left: 42px;
          overflow-x: hidden;
          overflow-y: hidden;
          height: 100%;
          background: $tertiaryBackground;
          min-width: 277px;
          padding-left: 8px;
          border-top: 1px solid $primaryBackground;
        }

        border-left: 1px solid $secondaryBackground;
        height: 100%;

        &__title {
          position: relative;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: inline-flex;
          width: 100%;
          height: 48px;
          padding-right: 12px;
          padding-left: 12px;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          border-bottom: 1px solid $secondaryBackground;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
            "Helvetica Neue", sans-serif;
          color: $secondaryFontColor;
          font-size: $fontSizeHeading;
          line-height: 27px;
          font-weight: 300;
          letter-spacing: 0.16px;

          & div.action-button {
            margin-left: auto;
            height: 31px;
            width: 30px;
            border: 1px solid $canvasPrimaryBackground;
            background-color: $canvasSecondaryBackground;
            text-align: center;
          }
          & div.action-button-absolute {
            position: absolute;
            top: 8px;
            right: 8px;
            border-bottom: 1px solid $canvasPrimaryBackground;
            cursor: pointer;
          }
          & div.action-button-absolute:hover {
            background-color: #494949;
          }
        }
      }
    }

    &-wrap {
      position: absolute;
      left: 0%;
      top: 0%;
      right: auto;
      bottom: 0%;
      z-index: 10;
      width: 41px;
      height: 100%;
      background-color: $secondaryAccentColor;
    }

    &-logo {
      margin-left: 11px;
      margin-top: 11px;
      width: 18px;
      height: 18px;
      & g {
        fill: $svgFill1;
      }
    }

    &-item {
      &-container {
        height: 44px;
        width: 40px;
        opacity: 1;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        position: relative;
        cursor: pointer;
      }

      &-active {
        border-top-color: $primaryBackground;
        border-bottom-color: $primaryBackground;
        background-color: $tertiaryBackground;
        width: 42px;
      }

      &-spacer {
        min-height: 250px;
        width: 100%;
      }

      &-image {
        margin-left: 11px;
        margin-top: 11px;
        width: 18px;
        height: 18px;
        opacity: 0.6;
        &:hover {
          opacity: 1;
        }
      }
      &-svg {
        margin-left: 11px;
        margin-top: 11px;
        width: 18px;
        height: 18px;
        & g {
          fill: $svgFill1;
        }
        & path {
          fill: $svgFill1;
        }
        & polygon {
          fill: $svgFill1;
        }
        &:hover {
          & g {
            fill: $svgFill2;
          }
          & path {
            fill: $svgFill2;
          }
          & polygon {
            fill: $svgFill2;
          }
        }
      }
    }

    &-bottom {
      &-container {
        position: absolute;
        left: 0%;
        top: auto;
        right: auto;
        bottom: 0%;
        z-index: 2;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        height: auto;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
      }
    }
  }

  &-toolbar {
    &-wrap {
      position: absolute;
      left: 41px;
      top: 0%;
      right: 0%;
      bottom: auto;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: 40px;
      padding-right: 384px;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      background-color: $secondaryAccentColor;
      z-index: 5;
    }
    &-right {
      &-container {
        display: flex;
        flex-direction: column;
      }
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;

      & .workflow-run-dropdown {
        background: $btnSecondaryBackground;
      }

      & .dropdown-list {
        overflow-y: hidden;
      }
    }
  }
  /* END OF TOOLBAR */
  &-detail {
    &-wrap {
      position: absolute;
      left: auto;
      top: 0%;
      right: 0%;
      bottom: 0%;
      width: 384px;
      border-left: 1px solid $primaryBackground;
      background-color: $tertiaryBackground;
      z-index: 10;
      overflow-y: scroll;
    }
    &-tab-wrap {
      & > div {
        height: 100%;
        width: 100%;
        justify-content: center;
        justify-items: center;
        align-items: center;
        display: flex;
      }
    }
    &-active-tab {
      border: 1px solid transparent;
      border-top: none;
      background: transparent;
      min-height: 40px;
      width: 33.33%;
      color: #f3f3f3;
      justify-items: center;
      justify-content: center;
    }
    &-inactive-tab {
      background: $secondaryAccentColor;
      border: 1px solid $canvasBorder1;
      border-top: none;
      min-height: 40px;
      width: 33.33%;
      &:hover {
        color: #f3f3f3;
      }
    }
    /* editor-detail-panel */
    &-panel {
      position: relative;
      padding: 12px;
      padding-bottom: 0;
      display: flex;
      margin-bottom: 20px;
      &-row {
        flex-direction: row;
      }
      /* editor-detail-panel-icon */
      &-icon {
        width: 15px;
        height: 15px;
        margin-top: 2px;
        margin-right: 8px;
        background-position: 50% 50%;
        background-size: 12px;
        background-repeat: no-repeat;
        opacity: 0.8;
        &-select {
          background-image: url("../../assets/images/icons/icon-select.svg");
        }
      }
      &-title {
        position: relative;
        display: flex;
        width: 100%;
        height: 48px;
        padding-right: 12px;
        padding-left: 12px;
        align-items: center;
        border-bottom: 1px solid $secondaryBackground;
        color: $secondaryFontColor;
        font-size: $fontSizeMediumLarge;
        line-height: 20px;
        font-weight: 300;
        letter-spacing: 0.16px;
      }
      &-column {
        flex-direction: column;
      }
    }

    /* editor-placeholder */
    &-placeholder {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      border-top: 1px solid $secondaryBackground;
      &-icon {
        width: 40px;
        height: 40px;
        background-image: url("../../assets/images/icons/icon-hand.svg");
        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
      }
      &-text {
        margin-top: 20px;
        opacity: 1;
        color: $secondaryFontColor;
        font-size: $fontSizeMediumSmall;
        line-height: 16px;
        font-weight: 300;
        text-align: center;
        letter-spacing: 0.16px;
      }
      &-inner-wrap {
        display: flex;
        width: 60%;
        height: 30%;
        margin-top: 20%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 0 0 auto;
      }
    }
  }
  /* END OF DETAIL */

  &-accordion {
    &-label {
      display: flex;
      width: 100%;
      height: 32px;
      padding-right: 12px;
      padding-left: 12px;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -ms-flex-align: center;
      align-items: center;
      background-color: $accordionPrimaryBackground;
      color: hsla(0, 0%, 95.3%, 0.7);
      font-size: $fontSizeSmall;
      line-height: 20px;
      font-weight: 400;
      text-transform: uppercase;
      cursor: pointer;
      position: sticky;
      z-index: 2;
      top: 0;
    }
    &-row {
      position: relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      height: 36px;
      padding-right: 12px;
      padding-left: 12px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      border-bottom: 1px solid $accordionBorderColor1;
      -webkit-transition: all 90ms ease;
      transition: all 90ms ease;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
      color: $accordionFontColor;
      font-size: $fontSizeMediumLarge;
      font-weight: 300;
      letter-spacing: 0.16px;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        background-color: $accordionAccent;
        color: $secondaryFontColor;
      }

      &-active {
        background-color: $accordionAccent;
        color: $secondaryFontColor;
      }

      &-icon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        background-image: url("../../assets/images/icons/icon-folder.svg");
        background-position: 50% 50%;
        background-size: auto;
        background-repeat: no-repeat;
        opacity: 0.7;
      }

      &__dropdown {
        position: absolute;
        right: 12px;
        width: 24px;
        height: 24px;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        padding: 0px;
        -webkit-transition: opacity 70ms ease;
        transition: opacity 70ms ease;
        &-icon {
          background-image: url("../../assets/images/icons/gear-icon__filled.svg");
          opacity: 0.4;
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: 12px;
          &:hover {
            opacity: 1;
          }
        }
        &-link {
          display: flex;
          height: 30px;
          padding: 0px 12px;
          flex-direction: row;
          align-items: center;
          opacity: 0.8;
          -webkit-transition: all 70ms ease;
          transition: all 70ms ease;
          color: $secondaryFontColor;
          font-size: $fontSizeMedium;
          cursor: pointer;
          &:hover {
            background-color: #606060;
          }
        }
        &-delete {
          &:hover {
            background-color: $deleteHoverBackground;
          }
        }
      } //end of __dropdown
    }
  }

  &-version {
    &-dropdown {
      &-open {
        position: absolute;
        top: 22px;
        width: 96px;
        margin-left: -68px;
        border-style: solid;
        border-width: 1px;
        border-color: $accordionPrimaryBackground;
        background-color: $tertiaryBackground;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.19);
        cursor: default;
        z-index: 20;
      }
      &-closed {
        display: none;
      }
    }
  }

  &-search {
    &-input {
      height: 32px;
      border-style: solid;
      border-width: 1px;
      border-color: $canvasPrimaryBackground;
      background-color: $ruleInputBackground;
      font-size: $fontSizeMediumLarge;
      width: 100%;
      font-weight: 300;
      color: $secondaryFontColor;
      padding-left: 0.5rem;
      padding-right: 2rem;
      padding-top: 0px;

      &-container {
        max-height: 34px;
        width: calc(100% - 24px);
        overflow: hidden;
      }
      &::placeholder {
        color: #bebebe;
      }
    }
    &-container {
      display: flex;
      align-items: center;
      padding: 8px;
      position: relative;
      border-bottom: 1px solid #2c2c2c;
    }
    &-clear {
      cursor: pointer;
      position: absolute;
      right: 1rem;
      padding: 4px;
      border-radius: 100%;
      &:hover {
        background: #3d3d3d;
      }
    }
  }

  /* editor-tags */
  &-tags {
    display: inline-block;
    height: 1.5rem;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    border-radius: 0.9375rem;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.32;

    &-wrapper {
      display: flex;
      padding-bottom: 10px;
      align-items: center;
    }
    &-filter {
      margin-right: 8px;
      margin-left: 0rem;
      padding-right: 0.125rem;
      border-style: none;
      border-width: 1px;
      border-color: $secondaryAccentColor;
      background-color: $accordionAccent;
      color: $secondaryFontColor;
      font-size: $fontSizeMediumSmall;
      cursor: pointer;
    }
    &-inner-wrapper {
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    }
  }
  &-icon-error {
    position: absolute;
    left: auto;
    top: 8px;
    right: 4px;
    bottom: auto;
    z-index: 2;
    width: 14px;
    height: 14px;
    background-image: url("../../assets/images/icons/icon-error.svg");
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

textarea {
  resize: none;
}

/* END OF EDITOR */

.editor-design-area-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 40px;
  left: 40px;
  z-index: 1;
}

/* VERSIONS */

/* DIAGRAM */
.srd-diagram {
  position: absolute;
  right: 0;
  left: 0px;
  flex-grow: 1;
  display: flex;
  cursor: move;
  overflow-y: auto;
  overflow-x: auto;
  justify-content: center;
  justify-items: center;
  width: 100%;
  max-width: 2000px;
  box-sizing: border-box;
  min-height: 80%;
}

.srd-diagram__selector {
  position: absolute;
  background-color: rgba(0, 192, 255, 0.2);
  border: solid 2px $primaryNodeColor;
}

.srd-link-layer {
  position: absolute;
  height: 100%;
  width: 100%;
  transform-origin: 0 0;
  overflow: visible !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.srd-node-layer {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}

.srd-node {
  position: absolute;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  user-select: none;
  cursor: move;
  pointer-events: all;
}

.srd-node--selected > * {
  border-color: $primaryNodeColor !important;
}

.srd-port {
  width: 15px;
  height: 15px;
  border-radius: 100px;
  background: $portBackground;
}

.srd-port:hover,
.srd-port.selected {
  background: $portSelectedColor;
}

.srd-default-node {
  background-color: $defaultNodeBackground !important;
  border-radius: 100px;
  font-family: sans-serif;
  color: $nodeFontColor;
  border: solid 2px $nodeBorderColor;
  overflow: visible;
  font-size: $fontSizeMediumSmall;
  width: 60px;
  height: 60px;
}

.srd-default-node__title {
  display: flex;
  white-space: nowrap;
  position: relative;
  right: -60px;
}

.srd-default-node__title > * {
  align-self: center;
}

.srd-default-node__title .fa {
  padding: 5px;
  opacity: 0.2;
  cursor: pointer;
}

.srd-default-node__title .fa:hover {
  opacity: 1;
}

.srd-default-node__name {
  flex-grow: 1;
  padding: 5px 5px;
}

.srd-default-node__ports {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  top: -23px;
  justify-content: space-between;
  .srd-default-node__out {
    display: flex;
    justify-content: flex-end;
  }
}

.srd-default-node__in,
.srd-default-node__out {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.srd-default-port {
  display: flex;
  margin-top: 1px;
  justify-content: center;
}

.srd-default-port > * {
  align-self: center;
}

.srd-default-port .name {
  display: none;
  padding: 0 5px;
}

.srd-default-ports .srd-default-label {
  background: $defaultPortsBackground;
  border: 1px solid $defaultPortsBorderColor;
  border-radius: 4px;
  color: $defaultPortsFontColor;
  display: inline-block;
  font-size: smaller;
  padding: 5px;
}

@keyframes dash {
  from {
    stroke-dashoffset: 24;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.srd-default-link path {
  fill: none;
  pointer-events: all;
}

.srd-default-link--path-selected {
  stroke: $primaryNodeColor !important;
  stroke-dasharray: 10, 2;
  animation: dash 1s linear infinite;
}

.srd-default-link__label {
  pointer-events: none;
}

.srd-default-link__label > div {
  display: inline-block;
  position: absolute;
}

.srd-default-link__point {
  fill: $defaultLinkBackground;
}

.srd-default-link--point-selected {
  fill: $primaryNodeColor;
}

.srd-demo-workspace {
  background: $srdDemoBackground;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
}

.editor-toolbar-json-content-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  border-right: 1px solid #1d1d1d;
  border-left: 1px solid #1d1d1d;
  height: 2.5rem;
  background-size: contain;
  z-index: 10;
  cursor: pointer;
  &:hover {
    background-color: #3d3d3d;
  }
}

.srd-demo-workspace__toolbar {
  padding: 5px;
  display: flex;
  flex-shrink: 0;
}

.srd-demo-workspace__toolbar button {
  background: $srdPrimaryBackground;
  font-size: $fontSizeLarge;
  padding: 5px 10px;
  border: none;
  color: $srdButtonFontColor;
  outline: none;
  cursor: pointer;
  margin: 2px;
  border-radius: 3px;
}

.srd-demo-workspace__toolbar button:hover {
  background: $primaryNodeColor;
}

.srd-demo-workspace__content {
  flex-grow: 1;
  height: 100%;
}

.docs-preview-wrapper {
  background: $srdPrimaryBackground;
  border-radius: 10px;
  overflow: hidden;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.neurops-runbook-canvas {
  height: calc(100vh - 62px);
  background-color: $srdPrimaryBackground !important;
  background-image: linear-gradient(
      0deg,
      transparent 24%,
      rgba(255, 255, 255, 0.05) 25%,
      rgba(255, 255, 255, 0.05) 26%,
      transparent 27%,
      transparent 74%,
      rgba(255, 255, 255, 0.05) 75%,
      rgba(255, 255, 255, 0.05) 76%,
      transparent 77%,
      transparent
    ),
    linear-gradient(
      90deg,
      transparent 24%,
      rgba(255, 255, 255, 0.05) 25%,
      rgba(255, 255, 255, 0.05) 26%,
      transparent 27%,
      transparent 74%,
      rgba(255, 255, 255, 0.05) 75%,
      rgba(255, 255, 255, 0.05) 76%,
      transparent 77%,
      transparent
    );
  background-size: 50px 50px;
  &.execution {
    margin-left: 255px;
  }
}

.neurops-runbook-canvas .pointui {
  fill: rgba(255, 255, 255, 0.5);
}

.button-run {
  padding-left: 15px;
  width: 130px;
  font-size: $fontSizeMediumLarge;
  background: $secondaryButtonBackground;
  &:hover {
    background: $secondaryButtonBackgroundHover;
  }
}

.button-save {
  padding-left: 15px;
  width: 165px;
  font-size: $fontSizeMediumLarge;
  &:hover {
    background: $primayButtonHoverBackground;
    border-color: $primayButtonHoverBackground;
  }
  position: relative;
}

.btn-svg__run {
  position: absolute;
  top: calc(50% - 8px);
  right: 1rem;
  width: 1rem;
  height: 1rem;
  flex: 0 0 auto;
  background-image: url("../../assets/images/icons/icon-play.svg");
  background-size: 12px;
  background-repeat: no-repeat;
}

.btn-svg__schedule {
  position: absolute;
  top: calc(50% - 8px);
  right: 1rem;
  width: 1rem;
  height: 1rem;
  flex: 0 0 auto;
  background-image: url("../../assets/images/icons/icon-hourglass.svg");
  background-size: 12px;
  background-repeat: no-repeat;
  filter: invert(100%);
}

.btn-svg__save {
  position: absolute;
  top: calc(50% - 6px);
  right: 10px;
  width: 1rem;
  height: 1rem;
  flex: 0 0 auto;
  background-image: url("../../assets/images/icons/save-icon-white.svg");
  background-size: 12px;
  background-repeat: no-repeat;
}

/* FORM STUFF */
.builder-form-label {
  color: hsla(0, 0%, 95.3%, 0.7);
  font-size: $fontSizeMediumSmall;
  font-weight: 300;
  padding-bottom: 5px;
}

.rule {
  position: relative;
  &-input {
    height: 32px;
    background: $ruleInputBackground;
    width: 100%;
    box-sizing: border-box;
    min-width: 352px;
    border: 1px solid $canvasPrimaryBackground;
    color: $primaryFontColor;
    line-height: 20px;
    font-size: $fontSizeMedium;
    padding-left: 10px;
    &::-webkit-input-placeholder {
      color: #bebebe;
    }
    &-error {
      border-bottom: 1px solid #fa4d56;
    }
  }
  &-list {
    margin-bottom: 20px;
    &-wrap {
      width: 100%;
      height: 32px;
      border-style: solid;
      border-width: 1px;
      border-color: $canvasPrimaryBackground;
      background-color: $tertiaryAccentColor;
      display: table;
      position: relative;
    }

    /* rule-list-item */
    &-item {
      color: $secondaryFontColor;
      font-size: $fontSizeMedium;
      margin-left: 10px;
      display: table-cell;
      vertical-align: middle;
      padding-left: 12px;
    }
    &-empty {
      position: relative;
      left: 1px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-style: dashed;
      border-color: $ruleListBorderColor;
      background-color: transparent;
      transition: all 70ms ease;
      color: $ruleListFontColor;
      font-size: $fontSizeMedium;
      cursor: pointer;
      margin-top: -10px;
      &:hover {
        background-color: $ruleListHoverBackground;
        color: $secondaryFontColor;
      }
    }
  }
  &-actions {
    position: absolute;
    right: 12px;
    top: 0px;
    display: flex;
    width: 80px;
    height: 32px;
    justify-content: flex-end;
    align-items: center;
    flex: 0 0 auto;
  }
  &-icon {
    width: 18px;
    height: 18px;
    margin-left: 6px;
    flex: 0 0 auto;
    background-position: 50% 50%;
    background-size: auto 12px;
    background-repeat: no-repeat;
    opacity: 0.7;
    transition: opacity 70ms ease;
    cursor: pointer;
    &-edit {
      background-image: url("../../assets/images/icons/icon-edit.svg");
    }
    &-delete {
      background-image: url("../../assets/images/icons/icon-trash.svg");
    }
    &:hover {
      opacity: 1;
    }
  }

  &-list {
    /* rule-list-header */
    &-div {
      background: $tertiaryBackground;
      z-index: 25;
    }
    &-header {
      position: relative;
      display: flex;
      width: 100%;
      height: 48px;
      padding-right: 12px;
      padding-left: 12px;
      align-items: center;
      border-bottom: 1px solid $secondaryBackground;
      border-top: 1px solid $secondaryBackground;
      color: $secondaryFontColor;
      font-size: $fontSizeMediumLarge;
      line-height: 20px;
      font-weight: 300;
      letter-spacing: 0.16px;
    }
    &-icon {
      width: 15px;
      height: 15px;
      margin-top: 2px;
      margin-right: 8px;
      background-image: url("../../assets/images/icons/icon-rule.svg");
      background-position: 50% 50%;
      background-size: 12px;
      background-repeat: no-repeat;
      opacity: 0.8;
    }
    &-close {
      position: absolute;
      right: 17px;
      display: flex;
      font-size: $fontSizeHeadingLarge;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 0 0 auto;
      opacity: 0.7;
      transition: opacity 70ms ease;
      cursor: pointer;
      color: #fff;
      &:hover {
        opacity: 1;
      }
    }
  }
}
/* OUTPUTS */
.div-table {
  width: 90%;
}

.close-editor-menu-icon {
  cursor: pointer;
}

.editor-menu-title {
  width: 94%;
}

.editor-previous-step-container {
  width: calc(100% - 8px);
  padding: 5px;
}

.editor-previous-step-label {
  margin: 0 auto;
  width: 100%;
  padding: 5px;
  margin-top: 1px;
  font-size: 0.75rem;
}

div.wait-node-time-option {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline;
  justify-content: space-evenly;
  margin: 20px 0;
  margin-left: 4px;
  font-size: 12px;
  & div {
    flex-basis: 100%;
    text-align: left;
    & input {
      width: 80%;
      text-align: inherit;
      padding: 0;
    }
  }
}

div.schedule-run {
  & .schedule-run-rate {
    display: flex;
    flex-direction: row;
    width: "70%";
    align-items: center;
    margin-bottom: 36px;
    margin-top: 28px;
    & select {
      min-width: 110px;
      border-left: 0px;
      border-right: 0px;
      border-top: 0px;
      border-bottom: 1px solid #8c8c8c;
      height: 41px;
      color: #f3f3f3;
      padding-left: 10px;
      cursor: pointer;
      margin-left: 8px;
      -webkit-appearance: none;
      background: url("../../assets/images/icons/select-carrot.svg") no-repeat
        right;
      background-color: #3d3d3d;
      background-position-x: 88px;
    }
    & select:hover {
      background-color: #4c4c4c;
    }
  }
  & div.space-line {
    border-bottom: 1px solid $selectOptionBorderColor;
    line-height: 0;
    margin: 20px 0 5px 0;
    & span {
      background: $selectBackgroundColor;
      padding: 1px 10px;
      padding-left: 0px;
      font-size: 13px;
      text-transform: uppercase;
      opacity: 0.7;
    }
  }
  & div.cron-expression {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
    & > div {
      padding-right: 16px;
    }
    & input {
      margin-top: 8px;
      height: 40px;
      width: 40px;
    }
    & select {
      width: 128px;
      border-left: 0px;
      border-right: 0px;
      border-top: 0px;
      border-bottom: 1px solid #8c8c8c;
      height: 41px;
      color: #f3f3f3;
      padding-left: 10px;
      cursor: pointer;
      margin-top: 8px;
      -webkit-appearance: none;
      background: url("../../assets/images/icons/select-carrot.svg") no-repeat
        right;
      background-color: #3d3d3d;
      background-position-x: 106px;
    }
  }
  & span {
    font-size: 10px;
  }
  & .expression-text {
    padding-top: 20px;
    text-align: center;
    color: $checkmarkBackgroundHover;
    font-size: 16px;
    text-transform: capitalize;
    margin-bottom: -40px;
  }
  & .expression-input {
    font-size: 15px;
    width: 360px;
    word-spacing: 30px;
    margin-bottom: 16px;
  }
}
.m-2 {
  margin: 1rem;
}

.no-pointer {
  pointer-events: none;
  cursor: not-allowed;
}

.helper {
  padding: 0.3rem 1rem;
  max-width: 350px;
  .reactour__close {
    color: #f3f3f3;
    &:hover {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}
.mask {
  opacity: 0.5;
  background: rgba(23, 23, 23, 0.5);
}

.welcome-dialog {
  &-image-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1.1rem;
    background: #6b6b6b;
    padding: 2rem;
    img {
      max-width: 80%;
    }
  }
  &-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    &-button {
      height: 2rem;
      width: 5rem;
      color: #f3f3f3;
      font-size: 12px;
      border: none;
      cursor: pointer;
      background-color: #6300ff;
      opacity: 1;
      &:hover {
        opacity: 0.9;
      }
    }
  }
}
.help-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
  background: #171717;
  opacity: 0;
}
.help {
  position: relative;
  &-dropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 3.5rem;
    margin-bottom: 0.5rem;
    &-carrot {
      position: absolute;
      left: -6px;
      top: 43px;
      bottom: 5px;
      z-index: -1;
      width: 13px;
      height: 13px;
      background-color: #3d3d3d;
      transform: rotate(45deg);
    }
    &-option {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #2e2e2e;
      background-color: #3d3d3d;
      height: 2rem;
      width: 10rem;
      font-size: 13px;
      padding: 0.4rem;
      cursor: pointer;
      &:hover {
        background-color: #535353;
      }
      &-icons {
        width: 16px;
        height: 16px;
        margin-right: 6px;
        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
        &-reset {
          background-image: url("https://assets.website-files.com/5d7fdec85e3a80dddda38d90/5f721d78d604ca5fc94c1cfd_reset-icon.svg");
        }
        &-support {
          background-image: url("https://assets.website-files.com/5d7fdec85e3a80dddda38d90/5f721c98340e0efbff617eb7_Bulb.svg");
        }
      }
    }
  }
}

.label-flex {
  display: flex;
  justify-content: space-between;
}
.label-link {
  display: inline-block;
  margin-bottom: 0.5rem;
  margin-right: 6px;
  flex: 0 0 auto;
  color: #bebebe;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 300;
  letter-spacing: 0.32px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.pt-0 {
  padding-top: 0px;
}
.json-output {
  max-height: 400px;
  min-height: 200px;
  overflow: scroll;
  background: #252525;
  padding-left: 12px;
}

//Editor Modal Styles

.modal-cover__editor-welcome {
  background: hsla(0, 0%, 95.3%, 0.35);
}

.modal-container__editor {
  box-shadow: 0 0.5em 3em rgba(0, 0, 0, 0.3);
  background-color: #3d3d3d;
  padding: 1rem;
  padding-top: 3.5rem;
  &-welcome {
    box-shadow: 0 0.5em 3em rgba(0, 0, 0, 0.3);
    max-width: 500px;
    background-color: #282828;
    padding: 0;
  }
  &-transactional {
    max-width: 500px;
    position: relative;
    padding: 1rem;
    padding-top: 3.5rem;
    background-color: #3d3d3d;
    box-shadow: 0 0.5em 3em rgba(0, 0, 0, 0.3);
  }
}
.modal-title__json {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding-left: 1rem;
  padding-top: 0;
  height: 41px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #282828;
  width: 100%;
  background: #555;
  font-size: 15px;
}

.modal-close__editor:hover {
  background-color: #6f6f6f;
}

.modal-content-container__editor {
  &-json {
    background-color: #252525;
    padding: 1rem;
  }
}

.modal-content__editor {
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 300;
  color: #f3f3f3;
  &-json {
    padding: 0;
    margin-bottom: 0;
    position: relative;
  }
  &-transactional {
    padding: 0;
    padding-top: 1rem;
  }
}

.modal-footer__editor {
  height: auto;
  background-color: transparent;
}

.modal-buttons-footer__editor {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.editor-dropdown__versions {
  width: 100%;
  display: inline-flex;
  height: 34px;
  border: 1px solid #303030;
  cursor: pointer;
  padding-left: 12px;
  color: #f3f3f3;
  font-weight: 300;
  font-size: 13px;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../../assets/images/icons/select-carrot.svg");
  background-repeat: no-repeat;
  background-position: 97%;
  background-color: #555;
  &:hover {
    background-color: #606060;
  }
}

.configue-output__wrap {
  margin: -16px;
  background-color: #3d3d3d;
}

.configure-modal__item-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.configure-modal__input {
  min-width: 60px;
  max-width: 60px;
}
.ht-100p {
  height: 100%;
}

.w-96 {
  width: 96%;
}

/* Tooltip customization */

.editor-menu-item-container > .__react_component_tooltip {
  padding: 4px 8px;
  font-size: 11px;
}

.editor-toolbar-json-content-button > .__react_component_tooltip {
  padding: 4px 8px;
  font-size: 11px;
}

/* END OF RULE CSS */
