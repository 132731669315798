//Signup card for mobile portrait
@media only screen and (max-width: 479px) {
  .signup-card {
    width: 100% !important;
  }
  .signup-container {
    align-items: start !important;
    margin-top: 15vh;
  }
  body {
    overscroll-behavior: auto;
    overflow-y: auto;
  }
}

//Signup card for mobile landscape
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .signup-card {
    width: 80% !important;
  }
  .signup-container {
    align-items: start !important;
    margin-top: 15vh;
  }
  body {
    overscroll-behavior: auto;
    overflow-y: auto;
  }
}

//Signup card for tablets
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .signup-card {
    width: 65% !important;
  }
  .signup-container {
    align-items: start !important;
    margin-top: 15vh;
  }
  body {
    overscroll-behavior: auto;
    overflow-y: auto;
  }
}

//Signup card for small screen sizes
@media only screen and (min-width: 992px) and (max-width: 1400px) {
  .signup-card {
    width: 65% !important;
  }
  .signup-container {
    align-items: start !important;
    margin-top: 15vh;
  }
  body {
    overscroll-behavior: auto;
    overflow-y: auto;
  }
}

//Prevents screen zooming into form input on mobile
@media only screen and (max-width: 991px) {
  input {
    font-size: 16px !important;
  }
}

//Formats Inputs For Mobile
input[type="text"],
input[type="email"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input {
  -webkit-tap-highlight-color: transparent;
}
input {
  border-radius: 0;
}
//End Mobile Styling

.signup {
  &-container {
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
  }
  &-card {
    display: flex;
    flex-direction: column;
    background: #282828;
    padding: 1rem 2rem 2rem;
    width: 35%;
    max-width: 29rem;
    &-header {
      color: #fff;
      font-size: 1.875rem;
      line-height: 2.675rem;
      font-weight: 400;
      letter-spacing: 0.32px;
      margin-bottom: 2.5rem;
    }
  }

  &-form {
    &-submit-btn {
      height: 80%;
      justify-content: center;
      padding: 0px;
    }
    &-input {
      display: flex;
      flex-direction: column;
      margin-bottom: 1.5rem;
      width: 100%;
      &-label {
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
      }
      &-text {
        height: 2.5rem;
        width: -webkit-fill-available;
        padding: 0px 1rem;
        border-style: none none solid;
        background-color: #3d3d3d;
        box-shadow: inset 0 0 0 2px transparent;
        color: #f3f3f3;
        font-weight: 400;
        font-size: 0.8rem;
      }
    }
    &-navigator {
      &-link {
        color: #bebebe;
        font-size: 14px;
        cursor: pointer;
        text-decoration: underline;
        font-weight: 300;
        &:visited {
          text-decoration: underline;
        }
        &:link {
          text-decoration: underline;
        }
      }
      &-container {
        margin-top: 1rem;
      }
    }
  }
}

.terms-conditions {
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
}
.d-flex {
  display: flex;
}

.three-dots {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 15px;
}

.dot {
  &-outer {
    display: flex;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
    border-radius: 100%;
  }

  &-active {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: #fff;
  }

  &-complete {
    display: flex;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    justify-content: center;
    align-items: center;
  }
}

.activation-code-helper {
  margin-bottom: 2rem;
  line-height: 30px;
  &-highlight {
    color: #fff;
  }
}

.border-red {
  border: 1px solid #fb4b53;
}

.h-18 {
  height: 18px;
}

.agree-check {
  height: 16px;
  width: 16px;
}
