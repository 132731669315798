@import "../../../scss/variables.scss";

.text-input__field-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-input {
  width: 400px;
  height: 2.5rem;
  margin-bottom: 0px;
  margin-top: 0px;
  padding: 0px 1rem;
  border-style: none none solid;
  border-width: 1px 0px;
  border-color: transparent transparent #8c8c8c;
  border-radius: 0px;
  background-color: #3d3d3d;
  box-shadow: inset 0 0 0 2px transparent;
  -webkit-transition: all 70ms ease;
  transition: all 70ms ease;
  color: #f3f3f3;
  font-weight: 300;
  font-size: $fontSizeLarge;
}

.text-input:hover {
  background-color: #4c4c4c;
}

.text-input:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  &:hover {
    background-color: #3d3d3d;
  }
}

.text-input:focus {
  border-bottom-color: transparent;
  box-shadow: inset 0 0 0 2px #f3f3f3;
}

.text-input::-webkit-input-placeholder {
  color: #bebebe;
  font-weight: 300;
}

.text-input:-ms-input-placeholder {
  color: #bebebe;
  font-weight: 300;
}

.text-input::-ms-input-placeholder {
  color: #bebebe;
  font-weight: 300;
}

.text-input::placeholder {
  color: #f3f3f3;
  opacity: 0.5;
  font-weight: 300;
}

.text-input__darker {
  width: 400px;
  height: 2.5rem;
  margin-bottom: 0px;
  padding: 0px 1rem;
  border-style: none none solid;
  border-width: 1px 0px;
  border-color: transparent transparent #8c8c8c;
  border-radius: 0px;
  background-color: #282828 !important;
  box-shadow: inset 0 0 0 2px transparent;
  -webkit-transition: all 70ms ease;
  transition: all 70ms ease;
  color: #f3f3f3;
  font-weight: 300;
}

.text-input__dark-bg {
  background-color: #282828;
}

.text-input__darker:hover {
  background-color: #353535;
}

.text-input__darker:focus {
  border-bottom-color: transparent;
  box-shadow: inset 0 0 0 2px #f3f3f3;
}

.text-input__darker::-webkit-input-placeholder {
  color: #bebebe;
  font-weight: 300;
}

.text-input__darker:-ms-input-placeholder {
  color: #bebebe;
  font-weight: 300;
}

.text-input__darker::-ms-input-placeholder {
  color: #bebebe;
  font-weight: 300;
}

.text-input__darker::placeholder {
  color: #bebebe;
  font-weight: 300;
}

.text-input-number {
  height: 32px;
  background: rgb(85, 85, 85);
  border: 1px solid rgb(48, 48, 48);
  line-height: 20px;
  height: 32px;
  font-size: 12px;
  padding-left: 6px;
  color: #f3f3f3;
  margin-top: 5px;
}

.reset-button__darker {
  background: #282828;
  color: #f3f3f3;
  background-color: transparent;
  border: 0;
  height: 36px;
  width: 38px;
  cursor: pointer;
  position: relative;
  right: 40px;
  bottom: 1px;
}
.reset-button__darker:hover {
  background: #353535;
}

.text-form {
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}
