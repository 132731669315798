@import "../../../scss/variables.scss";

/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 22px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: $fontSizeMedium;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 28px;
  padding-top: 2px;
  padding-bottom: 2px;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border: 1px solid #f3f3f3;
  border-radius: 50%;
  cursor: pointer;
}

.container input:checked ~ .checkmark {
  background-color: transparent;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  content: "";
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.radio-checked {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 50%;
}

.radio-checked:after {
  content: "";
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: $checkmarkAfter;
  display: block;
}

.radio-unchecked {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: $checkmarkUnchecked;
  border-radius: 50%;
}

.label-width {
  width: -webkit-fill-available;
}
