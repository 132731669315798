@import "../../scss/variables.scss";

.tableStyle {
  text-align: left;
  font-size: $fontSizeLarge;
}

.tableBorder {
  border: 1px solid;
}

.tableData {
  padding: 5px;
}

.appInfoDiv {
  margin: 1% 1%;
}
