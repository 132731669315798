@import "../../scss/variables.scss";

$controller-color: $lightblue;
$custom-color: $green;
$connector-color: $orange;
$default-color: $soft-white;
.snippet {
  &-tooltip-container {
    width: 13rem;
    height: 4rem;
    align-items: center !important;
    display: flex !important;
    justify-content: center !important;
    padding: 0 0 0 8px !important;
    color: #171717 !important;
    font-size: 12px !important;
    font-weight: 300 !important;
  }
  &-grid {
    display: grid;
    grid-auto-flow: row;
    grid-auto-columns: 81px;
    grid-auto-rows: 81px;
    grid-column-gap: 1px;
    grid-row-gap: 1px;
    -ms-grid-columns: 81px 82px 81px;
    grid-template-columns: 81px 82px 81px;
    -ms-grid-rows: 81px 81px;
    grid-template-rows: 81px;
    background-color: #2c2c2c;
  }
  &-block {
    display: flex;
    flex-wrap: wrap;
    width: 260px;
    background-color: #2c2c2c;
  }
  &-wrap-new {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #3d3d3d;
    -webkit-transition: all 70ms ease;
    transition: all 70ms ease;
    color: hsla(0, 0%, 95.3%, 0.7);
    font-size: $fontSizeMediumLarge;
    line-height: 15px;
    height: 42px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    text-align: left;
    cursor: -webkit-grab;
    cursor: grab;
    border-bottom: 1px solid #2c2c2c;
    transition: all 0s;

    &:hover {
      background: #505050;
      background-image: url("../../assets/images/icons/drag-icon.svg");
      background-repeat: no-repeat;
      background-position: right 16px center;
    }
  }
  &-wrap-new-disconnected {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #3d3d3d;
    color: hsla(0, 0%, 95.3%, 0.7);
    font-size: $fontSizeMediumLarge;
    line-height: 15px;
    height: 42px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    text-align: left;
    cursor: default;
    border-bottom: 1px solid #2c2c2c;
  }
  &-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #3d3d3d;
    -webkit-transition: all 70ms ease;
    transition: all 70ms ease;
    color: hsla(0, 0%, 95.3%, 0.7);
    font-size: $fontSizeMediumLarge;
    line-height: 15px;
    text-align: center;
    cursor: -webkit-grab;
    cursor: grab;

    &:hover {
      background: #666;
    }
  }
  &-name {
    bottom: 0px;
    display: block;
    padding-left: 12px;
    width: 100%;
    text-align: left;
    padding-right: 4px;
    font-size: $fontSizeMedium;
    line-height: 20px;
    position: relative;
    width: 100%;
    height: 46%;
    padding-top: 0px;
    justify-content: center;
    align-items: center;
    &.radio__xl {
      height: 30%;
      padding-bottom: 0px;
      padding-left: 16px;
      justify-content: flex-start;
      position: absolute;
      display: flex;
    }
  }
}

.snippet-icon {
  position: relative;
  display: flex;
  width: 28px;
  height: 54%;
  padding-bottom: 0px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  opacity: 0.9;
  &.radio__xl {
    height: 100%;
    width: 100%;
    padding-top: 16px;
    padding-right: 16px;
    padding-left: 16px;
    justify-content: flex-start;
    align-items: flex-start;
    opacity: 1;
  }
}

.opaque {
  opacity: 0.4;
}

.snippet-icon-image {
  mask-position: 50% 50%;
  mask-size: contain;
  mask-size: contain;
  mask-repeat: no-repeat;
  height: 20px;
  width: 20px;
}

.snippet-icon-default {
  mask-image: url("../../assets/images/snippets/icon-custom.svg");
  background-color: $default-color;
  height: 22px;
  width: 18px;
}

.snippet-success {
  mask-image: url("../../assets/images/snippets/pass-icon-white.svg");
  background-color: $controller-color;
}

.snippet-stop {
  mask-image: url("../../assets/images/snippets/stop-icon.svg");
  height: 20px;
  width: 18px;
  background-color: $controller-color;
}

.snippet-stop-vms {
  mask-image: url("../../assets/images/snippets/stop-icon.svg");
  height: 20px;
  width: 18px;
  background-color: $default-color;
}

.snippet-fail {
  mask-image: url("../../assets/images/snippets/icon-fail-white.svg");
  background-color: $controller-color;
}

.snippet-check {
  mask-image: url("../../assets/images/snippets/check-icon.svg");
  background-color: $default-color;
}

.snippet-custom {
  mask-image: url("../../assets/images/snippets/icon-custom.svg");
  background-color: $custom-color;
}

.snippet-attach {
  mask-image: url("../../assets/images/snippets/icon-attach.svg");
  background-color: $default-color;
}

.snippet-detach {
  mask-image: url("../../assets/images/snippets/icon-detach.svg");
  background-color: $default-color;
}

.snippet-replace {
  mask-image: url("../../assets/images/snippets/icon-replace-vms.svg");
  background-color: $default-color;
}

.snippet-conditional {
  mask-image: url("../../assets/images/snippets/icon-conditional.svg");
  background-color: $controller-color;
}

.snippet-webhook {
  mask-image: url("../../assets/images/snippets/icon-webhook.svg");
  background-color: $default-color;
}

.snippet-json {
  mask-image: url("../../assets/images/snippets/json-icon.svg");
  background-color: $default-color;
}

.snippet-delete {
  mask-image: url("../../assets/images/snippets/icon-trash.svg");
  background-color: $default-color;
  height: 22px;
  width: 16px;
}

.snippet-modify {
  mask-image: url("../../assets/images/snippets/icon-edit.svg");
  background-color: $default-color;
  height: 18px;
  width: 18px;
}

.snippet-start {
  mask-image: url("../../assets/images/snippets/icon-play.svg");
  background-color: $default-color;
}

.snippet-wait {
  mask-image: url("../../assets/images/snippets/icon-time.svg");
  background-color: $controller-color;
}

.snippet-launch {
  mask-image: url("../../assets/images/snippets/icon-launch.svg");
  background-color: $default-color;
}

.snippet-create {
  mask-image: url("../../assets/images/snippets/icon-plus.svg");
  background-color: $default-color;
  height: 22px;
  width: 20px;
}

.snippet-pause {
  mask-image: url("../../assets/images/snippets/icon-pause.svg");
  height: 26px;
  width: 26px;
  background-color: $controller-color;
}

.snippet-disk {
  mask-image: url("../../assets/images/snippets/icon-disk.svg");
  height: 20px;
  width: 26px;
  background-color: $default-color;
}

.snippet-cors {
  mask-image: url("../../assets/images/snippets/icon-cors.svg");
  height: 26px;
  width: 26px;
  background-color: $default-color;
}

.snippet-policy {
  mask-image: url("../../assets/images/snippets/icon-policy.svg");
  height: 20px;
  width: 18px;
  background-color: $default-color;
}

.connector-slack {
  mask-image: url("../../assets/images/snippets/icon-slack-logo.svg");
  height: 22px;
  width: 28px;
  background-color: $connector-color;
}

.connector-jira {
  mask-image: url("../../assets/images/snippets/icon-jira-logo.svg");
  height: 28px;
  width: 28px;
  background-color: $connector-color;
}

.snippet-eks {
  mask-image: url("../../assets/images/snippets/icon-kubernetes-logo.svg");
  height: 22px;
  width: 30px;
  background-color: $custom-color;
}

.snippet-deploy {
  mask-image: url("../../assets/images/snippets/icon-kubernetes-logo.svg");
  height: 22px;
  width: 30px;
  background-color: $default-color;
}

.snippet-email {
  mask-image: url("../../assets/images/snippets/icon-email.svg");
  height: 22px;
  width: 30px;
  background-color: $custom-color;
}

.snippet-pick {
  mask-image: url("../../assets/images/snippets/icon-bucket.svg");
  height: 20px;
  width: 30px;
  background-color: $default-color;
}

.snippet-kubectl {
  mask-image: url("../../assets/images/snippets/icon-kubernetes-logo.svg");
  height: 22px;
  width: 30px;
  background-color: $default-color;
}

.connector-datadog {
  mask-image: url("../../assets/images/snippets/icon-datadog-logo.svg");
  height: 28px;
  width: 28px;
  background-color: $connector-color;
}

.connector-ansible {
  mask-image: url("../../assets/images/snippets/icon-ansible-logo.svg");
  height: 22px;
  width: 28px;
  background-color: $custom-color;
}

.connector-instana {
  mask-image: url("../../assets/images/snippets/icon-instana-logo.svg");
  height: 28px;
  width: 28px;
  background-color: $connector-color;
}

.connector-cloudwatch {
  mask-image: url("../../assets/images/snippets/icon-cloudwatch.svg");
  height: 28px;
  width: 28px;
  background-color: $connector-color;
}

.connector-curl {
  mask-image: url("../../assets/images/snippets/icon-curl.svg");
  height: 21px;
  width: 28px;
  background-color: $custom-color;
}

.connector-lambda {
  mask-image: url("../../assets/images/snippets/icon-lambda.svg");
  height: 28px;
  width: 28px;
  background-color: $custom-color;
}

.connector-ssh {
  mask-image: url("../../assets/images/snippets/ssh-icon.svg");
  height: 22px;
  width: 28px;
  background-color: $custom-color;
}

.connector-jenkins {
  mask-image: url("../../assets/images/snippets/icon-jenkins.svg");
  height: 22px;
  width: 28px;
  background-color: $connector-color;
}

.connector-cloudformation {
  mask-image: url("../../assets/images/snippets/icon-cloudformation.svg");
  height: 22px;
  width: 28px;
  background-color: $custom-color;
}

.snippet-trustedadvisor {
  mask-image: url("../../assets/images/snippets/icon-trustedadvisor.svg");
  height: 22px;
  width: 28px;
  background-color: $default-color;
}

.connector-pagerduty {
  mask-image: url("../../assets/images/snippets/icon-pagerduty.svg");
  height: 22px;
  width: 28px;
  background-color: #e34da7;
}

.connector-splunk {
  mask-image: url("../../assets/images/snippets/icon-splunk.svg");
  height: 20px;
  width: 28px;
  background-color: $connector-color;
}

.connector-python {
  mask-image: url("../../assets/images/snippets/icon-python.svg");
  height: 22px;
  width: 28px;
  background-color: $custom-color;
}

.connector-twilio {
  mask-image: url("../../assets/images/snippets/twilio-icon.svg");
  height: 22px;
  width: 28px;
  background-color: $connector-color;
}

.snippet-terraform {
  mask-image: url("../../assets/images/snippets/icon-terraform-logo.svg");
  height: 28px;
  width: 28px;
  background-color: $custom-color;
}

.snippet-container {
  mask-image: url("../../assets/images/snippets/icon-container.svg");
  height: 22px;
  width: 28px;
  background-color: $custom-color;
}

.snippet-http {
  mask-image: url("../../assets/images/snippets/icon-http.svg");
  height: 22px;
  width: 28px;
  background-color: $custom-color;
}

.snippet-pulumi {
  mask-image: url("../../assets/images/snippets/icon-pulumi.svg");
  height: 22px;
  width: 28px;
  background-color: $custom-color;
}

.snippet-action {
  mask-image: url("../../assets/images/snippets/icon-aws-logo.svg");
  height: 30px;
  width: 30px;
  background-color: $custom-color;
}

.snippet-copy {
  mask-image: url("../../assets/images/snippets/icon-copy.svg");
  background-color: $default-color;
}

.snippet-filter {
  mask-image: url("../../assets/images/snippets/icon-filter.svg");
  background-color: $default-color;
}

.snippet-loop {
  mask-image: url("../../assets/images/snippets/icon-loop.svg");
  width: 24px;
  height: 24px;
  background-color: $controller-color;
}

.snippet-get-http {
  mask-image: url("../../assets/images/snippets/icon-fetch.svg");
  width: 30px;
  height: 30px;
  background-color: $default-color;
}

.snippet-fetch {
  mask-image: url("../../assets/images/snippets/icon-fetch.svg");
  width: 26px;
  height: 22px;
  background-color: $default-color;
}

.snippet-add {
  mask-image: url("../../assets/images/snippets/icon-add-user.svg");
  width: 21px;
  height: 20px;
  background-color: $default-color;
}

.snippet-provision {
  mask-image: url("../../assets/images/snippets/icon-provision-user.svg");
  width: 20px;
  height: 22px;
  background-color: $default-color;
}

.snippet-make {
  mask-image: url("../../assets/images/snippets/icon-make-public.svg");
  width: 26px;
  height: 22px;
  background-color: $default-color;
}

.snippet-describe {
  mask-image: url("../../assets/images/snippets/icon-describe.svg");
  width: 20px;
  height: 20px;
  background-color: $default-color;
}

.snippet-update {
  mask-image: url("../../assets/images/snippets/icon-update.svg");
  width: 24px;
  height: 20px;
  background-color: $default-color;
}

.snippet-tag {
  mask-image: url("../../assets/images/snippets/icon-tag.svg");
  width: 24px;
  height: 20px;
  background-color: $default-color;
}

.snippet-disable {
  mask-image: url("../../assets/images/snippets/icon-disable.svg");
  width: 22px;
  height: 20px;
  background-color: $default-color;
}

.snippet-upsert {
  mask-image: url("../../assets/images/snippets/icon-upsert.svg");
  width: 22px;
  height: 20px;
  background-color: $default-color;
}

.trigger-node {
  background-color: #e34da7;
}

.constant-autocomplete-list {
  height: auto;
  border: 1px solid #353333;
  width: calc(100% - 30px);
  padding: 5px;
  z-index: 1111;
  position: absolute;
  background: #2c2c2c;
  border-top: none;
}
.constant-autocomplete-list ul {
  padding: 5px;
  margin: 0;
  border-bottom: 1px solid #161616;
}
.constant-autocomplete-list ul:last-child {
  border-bottom: none;
}
.constant-autocomplete-list ul:hover {
  background-color: #4d4b4b;
}
.constant-autocomplete-list li {
  list-style-type: none;
  margin-bottom: 5px;
  font-size: $fontSizeMediumSmall;
  cursor: pointer;
}

.node-icon {
  position: absolute;
  top: 20px;
  left: 20px;
  height: 20px;
  width: 20px;
  mask-size: contain;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
}

.terraform-variable {
  margin-bottom: 1rem;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    .invalid-json {
      color: $warnBackground;
    }
    span {
      font-size: 0.75rem;
    }
  }
}

.snippet-disconnected {
  opacity: 0.5 !important;
}
