@import "../variables.scss";

.execution-status-side-bar {
  position: fixed;
  left: 0;
  top: 0;
  right: auto;
  bottom: 0;
  z-index: 99;
  width: 256px;
  height: 100vh;
  padding-top: 80px;
  border-right: 1px solid $executionsBorderRight;
  padding-left: 24px;
  background-color: $executionsSidebarBackground;
  .blocks-wrap {
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding-right: 0px;
    padding-left: 0px;
    .blocks-placeholder {
      position: relative;
      width: 100%;
      height: 100%;
      padding-top: 20px;
      padding-right: 18px;
      padding-left: 18px;
      .execution-step-block {
        position: relative;
        display: flex;
        width: 100%;
        height: 50px;
        margin-bottom: 12px;
        padding-right: 9px;
        padding-left: 16px;
        align-items: center;
        border-style: solid;
        border-width: 1px;
        border-color: $executionsStepBorderColor;
        background-color: $executionsStepBackground;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
          Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
          "Helvetica Neue", sans-serif;
        color: hsla(0, 0%, 95%, 0.8);
        font-weight: 300;
        .block-title {
          font-size: $fontSizeLarge;
        }
        .block-status {
          position: absolute;
          left: auto;
          top: 0%;
          right: 0%;
          bottom: 0%;
          display: flex;
          width: 50px;
          height: 100%;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          flex: 0 0 auto;
          .block-status-loading {
            position: relative;
            width: 20px;
            height: 20px;
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 auto;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            border-style: dotted;
            border-width: 2px;
            border-color: $executionsStatusLoadingBorder;
            border-radius: 100%;
          }
        }
        .block-status-indicator {
          position: absolute;
          left: 0%;
          top: 0%;
          right: auto;
          bottom: 0%;
          width: 2px;
          height: 100%;
          background-color: rgb(38, 201, 123);
        }
      }
    }
  }
  .title {
    position: relative;
    margin-top: 11px;
    margin-left: 18px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    color: $executionsTitle;
    font-size: $fontSizeHeadingSmall;
    line-height: 16px;
    font-weight: 300;
  }
  .description {
    position: relative;
    margin-top: 6px;
    margin-left: 18px;
    opacity: 0.53;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    color: $executionsTitle;
    font-size: $fontSizeMediumLarge;
    line-height: 17px;
    font-weight: 300;
  }
}
