@import "../../../scss/variables.scss";

ul,
li {
  box-sizing: border-box;
}

.flex-column {
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.select-container {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  margin-top: 0px;
  min-width: 200px;

  ul {
    list-style: none;
    list-style-type: none;
    margin: 0;
    padding: 0;
    list-style: none;
    box-sizing: border-box;
  }
  li {
    /* reset the margin and padding */
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  &-expanded {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  }
}

.li-option {
  padding-left: 15px !important;
  display: flex;
  align-items: center;
  background: #222;
  cursor: default;
  color: #bebebe;
  &span {
    color: #bebebe;
  }
  &:hover {
    background: #444;
  }
  &checkbox {
    text-rendering: auto;
    color: #000;
    color: initial;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0;
    text-shadow: none;
    display: inline-block;
    text-align: flex-start;
    margin: 0;
    font: 400 11px system-ui;
    -webkit-rtl-ordering: logical;
    cursor: text;
  }
  .unselect-label {
    padding-left: 28px;
  }
}

.styled-check-box {
  position: absolute;
  opacity: 1;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    opacity: 0.7;
  }

  & + label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 16px;
    height: 16px;
    background: #252525;
    border: 1px solid #3d3d3d;
  }

  &:hover + label:before {
    border: 1px solid #3d3d3d;
    background: rgb(243, 243, 243);
  }

  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  &:checked + label {
    opacity: 1;
  }

  &:checked + label:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}

.select-menu {
  display: block;
  margin: 0 auto;
  padding: 0;
  position: relative;
  width: 100%;
}

.select-menu li {
  width: 100%;
  align-items: center;
}

.select-menu ul {
  list-style: none;
  list-style-type: none;
  height: 0;
  left: 0;
  transition: all 0s ease;
  width: 100%;
  overflow-y: auto;
  margin: 0;
  height: 300px;
  box-sizing: border-box;
}

.select-menu-ul {
  overflow: visible;
}

.overlay {
  position: absolute;
  /* top: 41px; */
}

.required-unfulfilled {
  border-bottom: 1px solid red;
}

.pushdown {
  position: relative;
}

.select-menu ul li {
  margin: 0;
  padding: 0;
}

.expanded ul {
  transform: translateY(0);
}

.select-title-default {
  //border-style: solid;
  //border-color: $selectTitleBorderColor;
  //border-image: initial;
  background: $selectBackgroundColor;
  //border-width: 0px 0px 1px;
  color: #f3f3f3;

  a {
    color: #f3f3f3;
    align-items: center;
    text-decoration: none;
    padding-left: 15px;
  }
}

.title-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  &-expanded {
    border-bottom: 1px solid #8c8c8c;
  }
  &-contracted {
    border-bottom: 1px solid $selectTitleBorderColor;
  }
  &-contracted-required {
    border-bottom: 1px solid red;
  }
}
.title-bar:hover {
  background-color: #353535;
}
.select-option-default {
  font-size: $fontSizeLarge;
  position: relative;
  transition: all 70ms ease;
  background: $selectBackgroundColor;
  color: #fefefe;
  display: flex;
  align-items: center;
  color: rgb(190, 190, 190);
  text-decoration: none;
  font-size: $fontSizeLarge;
  align-items: center;
  width: 100%;
  padding-left: 14px !important;

  a {
    color: rgb(190, 190, 190);
    text-decoration: none;
    font-size: $fontSizeMedium;
    align-items: center;
    width: 100%;
    padding-left: 0;
  }
}

.select-option-default:hover {
  background-color: $selectOptionBackground;
  color: #f3f3f3;
  cursor: pointer;
}

.select-arrow {
  margin-right: 10px;
  cursor: pointer;
  transition: all 300ms ease;
}

.arrow-down {
  transform: rotate(0deg);
}

.arrow-right {
  transform: rotate(0deg);
}

.arrow {
  min-width: 20px;
  min-height: 20px;
  background: url("./arrow__down.svg");
  background-position: 50% 50%;
  background-size: 10px;
  background-repeat: no-repeat;
  color: #fff;
  filter: invert(100%);
}

.arrow-up {
  transform: rotate(180deg);
}

.select-input {
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  outline: none;
  height: 20px;
  font-size: $fontSizeLarge;
  color: #f3f3f3;
  margin-left: 5px;
}

.pointer {
  cursor: pointer;
}

.select-search-found-highlight {
  color: #a4ceff !important;
}

.select-clear {
  margin-left: 5px;
  color: white;
  width: 15px;
  height: 15px;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 3px;
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;

  &:hover {
    background: rgba(245, 30, 15, 0.25);
  }
}

.mySpan {
  background: red;
  color: white;
}

.select-icon {
  max-width: 15px;
  max-height: 15px;
  padding-left: 8px;
  padding-right: 5px;
}

// START SETTINGS INSTANA SELECT COMPONENT

.account-info__wrap
  > div
  > .select-container
  > .select-menu
  > .select-title-default {
  background: #3d3d3d;
}

.account-info__wrap
  > div
  > .select-container
  > .select-menu
  > .select-title-default
  > :hover {
  background: #4c4c4c;
}

.account-info__wrap
  > div
  > .select-container
  > .select-menu
  > .select-title-default
  > .flex-column
  > ul
  > li {
  background: #3d3d3d;
  font-size: 14px;
}

.account-info__wrap
  > div
  > .select-container
  > .select-menu
  > .select-title-default
  > .title-bar {
  border-bottom: 1px solid #8c8c8c;
}

.account-info__wrap
  > div
  > .select-container
  > .select-menu
  > .select-title-default
  > .flex-column
  > ul {
  background: #3d3d3d;
}

.account-info__wrap
  > div
  > .select-container
  > .select-menu
  > .select-title-default
  > .flex-column
  > ul
  > li
  > a
  > :hover {
  color: #fff;
}

.account-info__wrap
  > div
  > .select-container
  > .select-menu
  > .select-title-default
  > .flex-column
  > .pl-5 {
  padding-left: 8px;
}

.account-info__wrap
  > div
  > .select-container
  > .select-menu
  > .select-title-default
  > .flex-column
  > .flex-column
  > input {
  padding-left: 8px;
}

.account-info__wrap
  > div
  > .select-container
  > .select-menu
  > .select-title-default
  > .flex-column
  > .flex-column
  > .select-clear {
  position: absolute;
  right: 38px;
}

.account-info__wrap > .slack-notifications-toggle > .label {
  padding-top: 5px;
}

.slack-notifications > .slack-notifications-toggle > .label {
  padding-top: 5px;
}

// START EDITOR SLACK SELECT COMPONENT

.editor-select-container > .select-container {
  margin-top: 0px;
  margin-bottom: 16px;
}

.editor-select-container
  > .select-container
  > .select-menu
  > .select-title-default {
  background: #555;
}

.editor-select-container
  > .select-container
  > .select-menu
  > .select-title-default
  > .title-bar {
  height: 32px !important;
  font-size: 12px;
  border: 1px solid #303030;
  &:hover {
    background: #555;
  }
}

.editor-select-container
  > .select-container
  > .select-menu
  > .select-title-default
  > .title-bar
  > div
  > div
  > img {
  height: 14px;
  width: auto;
  padding-left: 10px;
  padding-right: 12px;
}

.editor-select-container
  > .select-container
  > .select-menu
  > .select-title-default
  > .title-bar
  > .pl-5 {
  padding-left: 0px;
}

.editor-select-container
  > .select-container
  > .select-menu
  > .select-title-default
  > .title-bar
  > .flex-column
  > input {
  width: 100%;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.editor-select-container
  > .select-container
  > .select-menu
  > .select-title-default
  > .title-bar
  > .flex-column
  > .select-clear {
  margin-right: 4px;
  height: 14px;
  width: 17px;
  background: #6f6f6f;
  &:hover {
    background: #8c8c8c;
  }
}

.editor-select-container
  > .select-container
  > .select-menu
  > .select-title-default
  > .flex-column
  > ul {
  background: #555;
  border-left: 1px solid #303030;
  border-right: 1px solid #303030;
}

.editor-select-container
  > .select-container
  > .select-menu
  > .select-title-default
  > .flex-column
  > ul
  > li {
  background: #555;
  height: 32px !important;
  font-size: 12px;
  padding-left: 10px !important;
  &:hover {
    background: #6f6f6f;
  }
}

.editor-select-container
  > .select-container
  > .select-menu
  > .select-title-default
  > .flex-column
  > ul
  > li
  > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.editor-select-container
  > .select-container
  > .select-menu
  > .select-title-default
  > .flex-column
  > ul
  > li
  > a {
  color: #f3f3f3;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:focus {
    outline: none;
  }
}

//START AWS SELECTOR COMPONENT

// START EDITOR SLACK SELECT COMPONENT

.editor-aws-container > .select-container {
  margin-top: 0px;
  margin-bottom: 16px;
}

.editor-aws-container
  > .select-container
  > .select-menu
  > .select-title-default {
  background: #555;
}

.editor-aws-container
  > .select-container
  > .select-menu
  > .select-title-default
  > .title-bar {
  height: 32px !important;
  font-size: 12px;
  border: 1px solid #303030;
  &:hover {
    background: #555;
  }
}

.editor-aws-container
  > .select-container
  > .select-menu
  > .select-title-default
  > .title-bar
  > div
  > div
  > img {
  height: 14px;
  width: auto;
  padding-left: 10px;
  padding-right: 12px;
}

.editor-aws-container
  > .select-container
  > .select-menu
  > .select-title-default
  > .title-bar
  > .pl-5 {
  padding-left: 5px;
}

.editor-aws-container
  > .select-container
  > .select-menu
  > .select-title-default
  > .title-bar
  > .flex-column
  > input {
  width: 100%;
  font-size: 12px;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.editor-aws-container
  > .select-container
  > .select-menu
  > .select-title-default
  > .title-bar
  > .flex-column
  > .select-clear {
  margin-right: 4px;
  height: 14px;
  width: 17px;
  background: #6f6f6f;
  &:hover {
    background: #8c8c8c;
  }
}

.editor-aws-container
  > .select-container
  > .select-menu
  > .select-title-default
  > .flex-column
  > ul {
  background: #555;
  border-left: 1px solid #303030;
  border-right: 1px solid #303030;
}

.editor-aws-container
  > .select-container
  > .select-menu
  > .select-title-default
  > .flex-column
  > ul
  > li {
  background: #555;
  height: 32px !important;
  font-size: 12px;
  padding-left: 10px !important;
  &:hover {
    background: #6f6f6f;
  }
}

.editor-aws-container
  > .select-container
  > .select-menu
  > .select-title-default
  > .flex-column
  > ul
  > li
  > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.editor-aws-container
  > .select-container
  > .select-menu
  > .select-title-default
  > .flex-column
  > ul
  > li
  > a {
  color: #f3f3f3;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:focus {
    outline: none;
  }
}
