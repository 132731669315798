.cw-control {
  height: 2.5rem;
  font-size: 14px;
  font-weight: 300;
  border: none;
  border-bottom: 1px solid #8c8c8c;
  background: #3d3d3d;
  &:hover {
    background: #4c4c4c;
  }
}

.cw-list-item {
  height: 40px;
  font-size: 14px;
}

.cw-value-container {
  padding-left: 10px;
}

.options {
  &-container {
    height: 6.5rem;
    background: #3d3d3d;
    width: 100%;
    border-radius: 0;
    border: 1px solid #8c8c8c;
  }
  &-list {
    height: 100%;
  }
}

.cw-form {
  &-container {
    height: auto;
  }
  &-title {
    padding: 0 1rem 2rem;
  }
  &-panel {
    padding: 0 1rem;
    margin-bottom: 1rem;
  }
  &-left {
    margin-right: 2rem;
    padding: 1.5rem;
  }
}
