//login card for mobile portrait
@media only screen and (max-width: 479px) {
  .login-card {
    width: 100% !important;
  }
}

//login card for mobile landscape
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .login-card {
    width: 80% !important;
  }
}

//login card for tablets
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login-card {
    width: 65% !important;
  }
}

//Prevents screen zooming into form input on mobile
@media only screen and (max-width: 991px) {
  input {
    font-size: 16px !important;
  }
}

//Formats Inputs For Mobile
input[type="text"],
input[type="email"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input {
  -webkit-tap-highlight-color: transparent;
}
input {
  border-radius: 0;
}
//End Mobile Styling

.login {
  &-container {
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
  }
  &-card {
    display: flex;
    flex-direction: column;
    background: #282828;
    padding: 1rem 2rem 2rem;
    width: 35%;
    max-width: 29rem;
    &-header {
      color: #fff;
      font-size: 1.875rem;
      line-height: 2.675rem;
      font-weight: 400;
      letter-spacing: 0.32px;
      margin-bottom: 2.5rem;
    }
  }

  &-form {
    &-submit-btn {
      height: 80%;
      justify-content: center;
      padding: 0px;
    }
    &-input {
      display: flex;
      flex-direction: column;
      margin-bottom: 1.5rem;
      &-label {
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
      }
      &-text {
        height: 2.5rem;
        width: -webkit-fill-available;
        padding: 0px 1rem;
        border-style: none none solid;
        background-color: #3d3d3d;
        box-shadow: inset 0 0 0 2px transparent;
        color: #f3f3f3;
        font-weight: 400;
        font-size: 0.8rem;
      }
    }
    &-navigator {
      &-link {
        color: #bebebe;
        font-size: 14px;
        cursor: pointer;
        text-decoration: underline;
        font-weight: 300;
        &:visited {
          text-decoration: underline;
        }
        &:link {
          text-decoration: underline;
        }
      }
      &-container {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
      }
    }
  }
}

.input-feedback {
  color: #ff8389;
  font-size: 13px;
}

.d-flex {
  display: flex;
}
.ml-n2 {
  margin-left: -2rem;
}
.password-eye-icon {
  margin-left: -2rem;
  margin-top: 0.7rem;
  cursor: pointer;
}
