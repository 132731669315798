.gs-copy-icon {
  align-self: flex-end;
  margin-bottom: -1rem;
  z-index: 1;
}

.gs-form-footer {
  display: flex;
  width: 100%;
  height: 3.5rem;
  margin-top: 1rem;
  &-btn {
    display: flex;
    align-items: center;
    padding-left: 16px;
    color: #fff;
    font-weight: 300;
    cursor: pointer;
    border: 2px solid;
    &-cancel {
      border-color: #6f6f6f;
      width: 45%;
      background-color: #6f6f6f;
    }
    &-submit {
      border-color: #6300ff;
      width: 50%;
      background-color: #6300ff;
    }
  }
}

.text-area {
  padding: 1rem;
  font-size: 14px;
}
