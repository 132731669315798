.tab {
  &-container {
    width: 100%;
    display: flex;
    margin: 2rem 0;
    border-bottom: 2px solid #252525;
    height: 3rem;
  }
  &-tabheader {
    height: auto;
    display: flex;
    padding: 0.5rem 1rem;
    align-items: center;
    width: 10rem;
    cursor: pointer;
    margin-bottom: -2px;
    border-bottom: 2px solid transparent;
    &:hover {
      border-bottom: 2px solid #6f6f6f;
      color: #f3f3f3;
    }
  }
  &-selected {
    border-bottom: 2px solid #6300ff;
    color: #fff;
    font-weight: 400;
  }
}
