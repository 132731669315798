.add-button {
  width: 100%;
  height: 32px;
  margin: 12px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-style: dashed;
  border-color: #7c7c7c;
  border-width: 1px;
  background-color: transparent;
  transition: all 70ms ease;
  color: hsla(0, 0%, 95.3%, 0.5);
  font-size: 12px;
  cursor: pointer;
  &:hover {
    background-color: rgba(94, 94, 94, 0.49);
    color: #f3f3f3;
  }
}
