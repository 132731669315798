.wf-btn-container > .delete:hover {
  background-color: #fb4b53;
  border: 2px solid #fb4b53;
  filter: brightness(1);
}

.wf-btn-container > .__react_component_tooltip {
  padding: 4px 8px;
  font-size: 11px;
}

.wf-btn {
  &-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 16rem;
    height: 2.5rem;
  }
  &-item {
    background-color: #6f6f6f;
    border: 2px solid #6f6f6f;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.2rem;
    height: 2.2rem;
  }
  &-item:hover {
    filter: brightness(1.2);
  }
}
