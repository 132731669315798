.snippets-clear-filters {
  padding: 35px 15px;

  & a {
    text-decoration: underline;
    color: #bebebe;
    display: inline-table;
    margin-left: 15px;
  }
}

.snippets-filter-input {
  width: 80%;
}
