@import "../../../../scss/variables.scss";

.instructions .list-row{flex-wrap: wrap;}
.instructions div:not(.json-pretty) > span{
    padding: 0 3px;
    background: #3a3939;
    margin: 0 3px;
    border-radius: 8px;
    display: inline-table;
}

.instructions .list-row a {
    margin: 0 3px;
    color: $purple;
}