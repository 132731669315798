@import "../../scss/variables.scss";

$success-stats-color: #3dbb61;
$failed-stats-color: #fb4b53;
$pending-stats-color: rgb(253, 155, 63);
$waiting-stats-color: rgb(253, 155, 63);
$cancelled-stats-color: #dfdfdf;
$inprogress-stats-color: rgb(253, 155, 63);
$timedout-stats-color: #dfdfdf;
$all-stats-color: rgb(99, 0, 255);

.all-stats {
  color: $all-stats-color;
}

.success-stats {
  color: $success-stats-color;
}

.failed-stats {
  color: $failed-stats-color;
}

.pending-stats {
  color: $pending-stats-color;
}

.waiting-stats {
  color: $waiting-stats-color;
}

.cancelled-stats {
  color: $cancelled-stats-color;
}

.inprogress-stats {
  color: $inprogress-stats-color;
}

.timedout-stats {
  color: $timedout-stats-color;
}

.data-cell {
  position: relative;
  display: inline-flex;
  width: auto;
  height: 26px;
  margin-top: 4px;
  padding-right: 8px;
  padding-left: 0px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: $fontSizeSmall;
  font-weight: 400;
  letter-spacing: 0.32px;
  text-transform: uppercase;
  min-width: none;

  &-Success {
    background-color: rgba(61, 187, 97, 0.23);
    color: $success-stats-color;
  }

  &-Failed {
    background-color: rgba(251, 75, 83, 0.23);
    color: $failed-stats-color;
    max-width: 70px;
  }

  &-Cancelled {
    background-color: hsla(0, 0%, 74.5%, 0.23);
    color: $cancelled-stats-color;
  }

  &-Waiting {
    background-color: rgba(245, 201, 8, 0.23);
    color: $waiting-stats-color;
  }

  &-Pending {
    background-color: rgba(245, 123, 8, 0.23);
    color: $pending-stats-color;
  }

  &-TimedOut {
    background-color: rgba(119, 118, 118, 0.5);
    color: $timedout-stats-color;
  }
}

.Cancelled-block {
  width: 26px;
  height: 26px;
  background-image: url("../../assets/images/icons/icon-cancelled.svg");
  background-position: 50% 50%;
  background-size: 12px;
  background-repeat: no-repeat;
  opacity: 0.8;
}

.Success-block {
  width: 26px;
  height: 26px;
  background-image: url("../../assets/images/icons/icon-green-checkmark.svg");
  background-position: 50% 50%;
  background-size: 12px;
  background-repeat: no-repeat;
}

.Failed-block {
  width: 26px;
  height: 26px;
  background-image: url("../../assets/images/icons/icon-failed.svg");
  background-position: 50% 50%;
  background-size: 12px;
  background-repeat: no-repeat;
}

.Loading-block {
  width: 26px;
  height: 26px;
  background-image: url("../../assets/images/icons/icon-loading-small.svg");
  background-position: 50% 50%;
  background-size: 12px;
  background-repeat: no-repeat;
  animation: spin 1s infinite linear;
}

.TimedOut-block {
  width: 26px;
  height: 26px;
  background-image: url("../../assets/images/icons/icon-hourglass.svg");
  background-position: 50% 50%;
  background-size: 12px;
  background-repeat: no-repeat;
}

.Waiting-block {
  width: 26px;
  height: 26px;
  background-image: url("../../assets/images/icons/icon-waiting.svg");
  background-position: 50% 50%;
  background-size: 12px;
  background-repeat: no-repeat;
  animation: spin 1s infinite linear;
}

.Pending-block {
  width: 26px;
  height: 26px;
  background-image: url("../../assets/images/icons/icon-waiting.svg");
  background-position: 50% 50%;
  background-size: 12px;
  background-repeat: no-repeat;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.version-opacity {
  opacity: 0.7;
}
