@import "../variables.scss";

#runbook-create-detail-modal,
#runbook-delete-confirm-modal,
#runbook-execute-modal {
  .modal-content {
    border-radius: 0;
    padding: 24px 0 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;

    label {
      color: $modalLabelFontColor;
      font-size: $fontSizeMedium;
      font-weight: 300;
    }

    .modal-header {
      border-bottom: 0;
      padding: 0 24px;
      margin-bottom: 20px;

      .modal-title {
        color: $modalTitleColor;
        font-size: $fontSizeHeading;
        line-height: 26px;
        font-weight: 300;
      }
    }

    .modal-body {
      padding: 0 24px;

      .form-control {
        border: 0;
        border-bottom: 1px solid $modalBorderColor;
        background-color: $modalFormBackground;
        color: $modalFormFontColor;
        font-size: $fontSizeMedium;
        border-radius: 0;
      }

      .tag {
        display: inline-flex;
        height: 24px;
        margin-top: 12px;
        padding-right: 8px;
        padding-left: 8px;
        align-items: center;
        border-radius: 24px;
        background-color: $modalTagBackground;
        color: $modalTagFontColor;
        line-height: 12px;
        margin-left: 10px;
        flex-flow: row;

        .delete-tag {
          cursor: pointer;
        }
      }
    }

    .modal-footer {
      border-top: 0;
      padding: 0;
      height: 66px;
      justify-content: center;

      button {
        width: 50%;
        font-size: $fontSizeLarge;
        height: 66px;
        border-radius: 0;
        color: $primaryModalButtonFontColor;
        margin: 0;

        &.cancel {
          background-color: $modalCancelButtonFontColor;
        }

        &.create-new {
          background-color: $primaryModalButtonBackground;
        }
      }
    }
  }
}
