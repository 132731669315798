div.notification-item {
  position: relative;
  color: #282828;
  padding: 16px 44px;
  width: 100%;
  overflow-wrap: break-word;
  background-repeat: no-repeat;
  background-position: 14px 15px;
  opacity: 0;
  transition: opacity 200ms linear;

  &.display {
    opacity: 1;
  }

  &:nth-child(n + 2) {
    margin-bottom: 6px;
  }
  &.error {
    background-color: #f3f3f3;
    background-image: url("../../../assets/images/icons/icon-error.svg");
    border-left: 3px solid #e74c3c;
    background-size: 18px;
  }

  &.info {
    background-color: #f3f3f3;
    background-image: url("../../../assets/images/icons/icon-info-filled.svg");
    border-left: 3px solid #4b3eff;
    background-size: 18px;
  }

  &.success {
    background-color: #f3f3f3;
    background-image: url("../../../assets/images/icons/icon-success-filled.svg");
    border-left: 3px solid #08a70c;
    background-size: 18px;
  }
  &.removed {
    opacity: 0.3;
  }
  & .title {
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 5px;
    margin: 0 auto;
  }
  & .message {
    line-height: 20px;
  }
  & .remove {
    position: absolute;
    top: 14px;
    right: 8px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: block;
    font-size: 15px;
    filter: invert(1);
  }
}

.remove > img {
  height: 9px;
  width: 9px;
  opacity: 0.7;
}
div#notification-holder {
  position: fixed;
  width: 320px;
  z-index: 99999;
  bottom: 20px;
  right: 20px;
  & .list {
    flex-direction: column-reverse;
    display: flex;
    transition: flex-direction 5000ms linear;
  }
}
