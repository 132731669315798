.rs {
  .rs__placeholder {
    color: #f3f3f3;
    opacity: 0.5;
  }
  &-dropdown-icon {
    padding: 4px;
    &-delete {
      margin-left: auto;
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }
    &-collapsed {
      transform: rotate(0deg);
      transition: transform 0.2s ease;
    }
    &-expanded {
      transform: rotate(180deg);
      transition: transform 0.2s ease;
    }
  }

  &-option {
    font-size: 12px;
    overflow-x: auto;
    height: 2rem;
    cursor: pointer;
    padding-left: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &:active {
      background: #545454;
    }
    &:hover {
      background: rgba(255, 255, 255, 0.1);
      color: #f3f3f3;
    }
    &-selected {
      color: #84bdff;
      background: rgba(255, 255, 255, 0.1);
      &:hover {
        color: #f3f3f3;
      }
    }
    &-inner {
      white-space: nowrap;
    }
  }

  &-control {
    display: flex;
    align-items: center;
    background: #555;
    border-radius: 0;
    border: 1px solid #303030;
    height: 2rem;
    min-height: 2rem;
    font-size: 12px;
    box-shadow: 0;
    &:hover &:focus {
      border: 1px solid #282828;
      box-shadow: 0 #282828;
    }
  }

  &-value {
    &-container {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 2px 8px;
      overflow: hidden;
    }

    &-single {
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-left: 2px;
      margin-right: 2px;
      max-width: calc(100% - 8px);
      overflow: hidden;
      box-sizing: border-box;
      color: #f3f3f3;
    }
  }

  &-menu {
    position: absolute;
    background: #545454;
    z-index: 3;
    margin-top: -1px;
    width: 100%;
    border-radius: 0;
    border: 1px solid #282828;
    &-list {
      max-height: 10rem;
      overflow-y: auto;
    }
  }
}

.required-unfulfilled {
  border-bottom: 1px solid red;
}
