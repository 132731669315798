@import "../../scss/variables.scss";

.no-scroll {
  overflow: hidden;
}
.settings-connector {
  overflow-y: scroll;
  height: -webkit-fill-available;
}

$error-color: red;

.connectors-wrap {
  position: relative;
}
.integration-tile {
  position: relative;
  width: 194px;
  height: 164px;
  margin-right: 1.2rem;
  border: none;
  margin-bottom: 1.2rem;
  background-color: #282828;
  -webkit-transition: all 90ms ease;
  transition: all 90ms ease;
  color: #f3f3f3;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    filter: brightness(130%);
  }
}
.settings-icon.radio__xl {
  height: 100%;
  width: 100%;
  padding-top: 16px;
  padding-right: 16px;
  padding-left: 16px;
  justify-content: flex-start;
  align-items: flex-start;
  opacity: 1;
}
.settings-icon {
  position: relative;
  display: flex;
  width: 28px;
  height: 54%;
  padding-bottom: 0px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  opacity: 0.9;
}
.integration-title {
  font-size: $fontSizeHeading;
  line-height: 28px;
}
.bg-aws {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-image: url(https://assets.website-files.com/5d5b7e4…/5df7e34…_aws-logo.svg);
  background-position: 100% 25%;
  background-size: auto 80px;
  background-repeat: no-repeat;
  opacity: 0.12;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.settings-name.radio__xl {
  height: 30%;
  padding-bottom: 0px;
  padding-left: 16px;
  flex-direction: row;
  justify-content: flex-start;
  position: absolute;
  display: flex;
}
.settings-name {
  position: relative;
  bottom: 0px;
  display: block;
  padding-left: 12px;
  width: 100%;
  text-align: left;
  padding-top: 0px;
  padding-right: 4px;
  align-items: center;
  font-size: $fontSizeMedium;
  line-height: 20px;
}
.status-icon {
  width: 10px;
  height: 10px;
  margin-right: 8px;
  flex: 0 0 auto;
  border-radius: 100%;
  background-color: #3dbb61;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.status {
  display: inline-block;
  margin-bottom: 0rem;
  flex: 0 0 auto;
  color: #bebebe;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 300;
  letter-spacing: 0.32px;
  cursor: default;
}
.status-icon.status-icon__inactive {
  background-color: #606060;
}
.bg-aws {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-image: url("https://assets.website-files.com/5d5b7e4beaf3b7f4fce62862/5df7e34ff21f7749f25df018_aws-logo.svg");
  background-position: 100% 25%;
  background-size: auto 80px;
  background-repeat: no-repeat;
  opacity: 0.12;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.bg-slack {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-image: url("https://assets.website-files.com/5d5b7e4beaf3b7f4fce62862/5df7ffa2969f452cc8664942_slack-new-logo.svg");
  background-position: 100% 25%;
  background-size: auto 80px;
  background-repeat: no-repeat;
  opacity: 0.12;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.bg-datadog {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-image: url("https://assets.website-files.com/5d5b7e4beaf3b7f4fce62862/5db7263afbe1d7ff62b3b5b8_datadog-logo__minimal.svg");
  background-position: 100% 25%;
  background-size: auto 80px;
  background-repeat: no-repeat;
  opacity: 0.1;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.bg-ansible {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-image: url("../../assets/images/snippets/icon-ansible-logo.svg");
  background-position: 100% 25%;
  background-size: auto 80px;
  background-repeat: no-repeat;
  opacity: 0.12;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.bg-splunk {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-image: url("../../assets/images/snippets/icon-splunk.svg");
  background-position: 100% 25%;
  background-size: auto 80px;
  background-repeat: no-repeat;
  opacity: 0.06;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.bg-pulumi {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-image: url("../../assets/images/snippets/icon-pulumi.svg");
  background-position: 100% 25%;
  background-size: auto 80px;
  background-repeat: no-repeat;
  opacity: 0.06;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.bg-cloudwatch {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-image: url("../../assets/images/snippets/icon-cloudwatch.svg");
  background-position: 100% 25%;
  background-size: auto 80px;
  background-repeat: no-repeat;
  opacity: 0.1;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.bg-cloudformation {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-image: url("../../assets/images/snippets/icon-cloudformation.svg");
  background-position: 100% 25%;
  background-size: auto 80px;
  background-repeat: no-repeat;
  opacity: 0.08;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.bg-opsgenie {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-image: url("../../assets/images/snippets/icon-opsgenie.svg");
  background-position: 100% 25%;
  background-size: auto 80px;
  background-repeat: no-repeat;
  opacity: 0.08;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.bg-jira {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-image: url("https://assets.website-files.com/5d5b7e4beaf3b7f4fce62862/5df7ff2d775eaa1bf116609f_jira-3.svg");
  background-position: 100% 25%;
  background-size: auto 80px;
  background-repeat: no-repeat;
  opacity: 0.15;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.bg-terraform {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-image: url("https://assets.website-files.com/5d5b7e4beaf3b7f4fce62862/5df806c4775eaab2ed169f1a_terraform-svg.svg");
  background-position: 100% 25%;
  background-size: auto 80px;
  background-repeat: no-repeat;
  opacity: 0.29;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.bg-instana {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-image: url("../../assets/images/snippets/icon-instana-logo.svg");
  background-position: 100% 25%;
  background-size: auto 80px;
  background-repeat: no-repeat;
  opacity: 0.08;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.bg-terraform_cli {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-image: url("https://assets.website-files.com/5d5b7e4beaf3b7f4fce62862/5df806c4775eaab2ed169f1a_terraform-svg.svg");
  background-position: 100% 25%;
  background-size: auto 80px;
  background-repeat: no-repeat;
  opacity: 0.29;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.bg-git {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-image: url("../../assets/images/snippets/icon-git-logo.svg");
  background-position: 100% 25%;
  background-size: auto 80px;
  background-repeat: no-repeat;
  opacity: 0.08;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.bg-s3 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-image: url("../../assets/images/snippets/icon-s3.svg");
  background-position: 100% 25%;
  background-size: auto 80px;
  background-repeat: no-repeat;
  opacity: 0.08;
  -webkit-filter: contrast(1.1);
  filter: contrast(1.1);
}

.bg-jenkins {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-image: url("../../assets/images/snippets/icon-jenkins.svg");
  background-position: 100% 25%;
  background-size: auto 80px;
  background-repeat: no-repeat;
  opacity: 0.1;
}

.bg-pagerduty {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-image: url("../../assets/images/snippets/icon-pagerduty.svg");
  background-position: 100% 25%;
  background-size: auto 80px;
  background-repeat: no-repeat;
  opacity: 0.08;
}

.bg-api-keys {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-image: url("../../assets/images/snippets/icon-api-keys-logo.svg");
  background-position: 100% 25%;
  background-size: auto 80px;
  background-repeat: no-repeat;
  opacity: 0.1;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.bg-ssh {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-image: url("../../assets/images/snippets/ssh-icon.svg");
  background-position: 100% 25%;
  background-size: auto 74px;
  background-repeat: no-repeat;
  opacity: 0.08;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.bg-twilio {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-image: url("../../assets/images/snippets/twilio-icon.svg");
  background-position: 100% 25%;
  background-size: auto 80px;
  background-repeat: no-repeat;
  opacity: 0.08;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.overflow-items {
  display: contents;
  cursor: pointer;
}

.disabled {
  pointer-events: none;
}
.w-form-fail {
  color: $error-color;
}

.api-key-value {
  display: flex;
  width: 100%;
  input {
    width: 80%;
    font-size: 13px;
    font-weight: 400;
    background-color: transparent;
    border: 0;
    color: #bebebe;
  }
  img {
    margin-left: 1rem;
  }
}

.account-id {
  padding-top: 1rem;
  color: #5ca8ff;
  padding-left: 1rem;
}

.aws-settings-list > div > div {
  overflow-x: inherit;
}

.target-account-info-container {
  margin-top: 32px;
  padding-left: 16px;
}

.default-tag {
  font-size: 10px;
  background-color: #656565;
  color: #f3f3f3;
  padding: 6px 9px;
  display: inline-block;
  border-radius: 2px;
  text-transform: uppercase;
  margin-left: 10px;
}

.modal-select {
  &-control {
    height: 2.5rem;
    font-size: 14px;
    font-weight: 300;
    border: none;
    border-bottom: 1px solid #8c8c8c;
    background: #3d3d3d;
    &:hover {
      background: #4c4c4c;
    }
  }
  &-list-item {
    height: 40px;
    font-size: 14px;
  }
  &-options {
    &-container {
      height: 6.5rem;
      background: #3d3d3d;
      width: 100%;
      border-radius: 0;
      border: 1px solid #8c8c8c;
    }
    &-list {
      height: 100%;
    }
  }
  &-value-container {
    padding-left: 10px;
  }
}
.cloudwatch-instruction-json-copy-icon {
  top: 20px;
}
