$strokeColor: #574bfa;

.shape1,
.shape2 {
  stroke-dasharray: 1120;
  stroke-width: 2px;
  fill: transparent;
  stroke: $strokeColor;
  transition-timing-function: linear;
  transition: stroke-dashoffset 2s, stroke-dasharray 5s;
}
.shape1 {
  stroke-dashoffset: 1120;
}
.shape2 {
  stroke-dashoffset: -1120;
}

.svg-wrapper:hover .shape1,
.svg-wrapper:hover .shape2 {
  stroke-dashoffset: 0;
  stroke-dasharray: 1120;
}
