@import "../../../scss/variables.scss";

.data-table-container {
  box-sizing: border-box;
  width: 100%;
  margin-top: 0px;
  background: inherit;
  position: relative;
  color: inherit;

  & header {
    margin: 0;
    padding: 0;
    max-height: 0;
    visibility: hidden;
    display: none;
  }

  & a {
    display: block;
    color: #5ca8ff;
    padding: 3px 8px;
    background-color: rgba(255, 255, 255, 0.05);
    font-size: $fontSizeMedium;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      background-color: #3d3d3d;
    }
  }
  & span.tag {
    display: block;
    background-color: #3d3d3d;
    padding: 1px 8px;
    line-height: 23px;
    margin-left: 0px;
    margin-right: 8px;
  }
}

.rdt_TableBody {
  overflow-y: unset !important;
}
