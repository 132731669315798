@import "../variables.scss";

.login-header {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 100;
  display: flex;
  width: 100vw;
  height: 52px;
  padding-right: 20px;
  padding-left: 20px;
  align-items: center;
  border-bottom: 1px solid $tertiaryBackground;
  background-color: $tertiarySecondaryBackground;
}

.login-wrap {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f3f3f3;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  text-align: left;
  .login-block {
    width: 440px;
    height: 374px;
    padding: 24px;
    background-color: #fff;
    .login-block-title-wrap {
      width: 100%;
      height: 50px;
      .login-block-title {
        margin-top: 4px;
        color: $primaryBackground;
        font-size: 24px;
        line-height: 24px;
        font-weight: 300;
      }
    }
    .form-block {
      color: $primaryBackground;
      font-weight: 400;
      margin: 0 0 15px;
      .form-field-label {
        display: block;
        margin-bottom: 5px;
        margin-top: 20px;
        font-size: $fontSizeLarge;
        line-height: 24px;
        font-weight: 300;
      }
      .text-field {
        display: block;
        width: 100%;
        padding: 8px 12px;
        margin: 0;
        margin-bottom: 10px;
        font-size: $fontSizeLarge;
        line-height: 1.42857143;
        color: $textField1FontColor;
        vertical-align: middle;
        background-color: #ffffff;
        border: 1px solid #cccccc;
        height: 48px;
        border-style: solid;
        border-width: 0px 0px 1px;
        border-color: #000 #000 #8c8c8c;
        background-color: #f3f3f3;
        font-weight: 300;
      }
      .login-btn {
        display: flex;
        height: 48px;
        padding-right: 18px;
        padding-left: 18px;
        align-items: center;
        flex: 0 0 auto;
        background-color: $primaryAccentColor;
        color: #fff;
        line-height: 30px;
        font-weight: 300;
        text-decoration: none;
        position: relative;
        width: 150px;
        margin-top: 28px;
        padding-right: 48px;
        transition: background-color 200ms ease;
        font-size: $fontSizeLarge;
        cursor: pointer;
      }
      .login-btn-icon {
        position: absolute;
        right: 0px;
        width: 16px;
        height: 16px;
        margin-top: 1px;
        margin-right: 18px;
        background-image: url("https://assets.website-files.com/5cc3d0860871f792e924489c/5cc746674e0e4c1a0030e5df_arrow-right.svg");
        background-position: 0px 0px;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }
}
