@import "../variables.scss";

div {
  box-sizing: border-box;
}

.flex-table {
  display: table;
  width: 100%;
}
.flex-row-header {
  display: table-row;
  background: $table-header-color;
}
.flex-row-header > div {
  display: table-cell;
  padding: 5px;
  padding-left: 16px;
  height: 32px;
  line-height: 32px;
}
.flex-row {
  display: table-row;
}

.flex-row > div {
  display: table-cell;
  background: $table-bg-color;
  vertical-align: top;
  height: 32px;
  line-height: 32px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 4px;
}

.table-col-2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
}
.table-col-left {
  padding: 5px;
  padding-left: 16px;
  width: 25%;
  background: $table-header-color;
  border-bottom: 1px solid #181818;
  border-right: 2px solid #181818;
  padding-top: 16px;
  padding-bottom: 10px;
  height: 50px;
}
.table-col-right {
  padding: 5px;
  padding-top: 16px;
  height: 50px;
  padding-bottom: 10px;
  padding-left: 16px;
  width: 75%;
  background: $table-bg-color;
  border-bottom: 1px solid $dark-bg-color;
  overflow-x: auto;
  overflow-y: hidden;
}

.pct-15 {
  width: 15%;
}
.pct-20 {
  width: 20%;
}
.pct-25 {
  width: 25%;
}
.pct-30 {
  width: 30%;
}
.pct-35 {
  width: 35%;
}

.align-left > div {
  text-align: left;
}
