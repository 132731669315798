.warning-card {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-left: 3px solid #fed23a;
  background-color: #3d3d3d;
  color: hsla(0, 0%, 95.3%, 0.9);
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.16px;
  max-width: 25rem;
  &-header {
    display: flex;
    justify-content: space-between;
    width: auto;
    align-items: center;
  }
  &-link {
    cursor: pointer;
    color: #5ca8ff;
    font-size: 12px;
    line-height: 1.5rem;
    font-weight: 300;
    letter-spacing: 0.16px;
    text-decoration: none;
    &:hover {
      color: #7cb9ff;
      text-decoration: none;
    }
  }
  &-text {
    margin-top: 1rem;
    font-size: 12px;
    line-height: 17px;
  }
  &-ul {
    padding-left: 15px;
  }
}
