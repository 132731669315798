.warning {
  &-container {
    width: 100%;
    display: flex;
    max-width: none;
    margin-top: 2rem;
    margin-bottom: 0rem;
    border-left: 3px solid #fed23a;
    background-color: #3d3d3d;
    height: auto;
    min-height: 3rem;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;
    flex-direction: row;
    &-cross {
      cursor: pointer;
      margin-right: 5px;
      padding: 8px 2px;
      &:hover {
        background-color: #524f4f;
      }
    }
  }
  &-text {
    color: #f3f3f3;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 400;
    letter-spacing: 0.16px;
  }
  &-link {
    cursor: pointer;
    color: #5ca8ff;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 400;
    letter-spacing: 0.16px;
    text-decoration: none;
    &:hover {
      color: #7cb9ff;
      text-decoration: none;
    }
  }
}
