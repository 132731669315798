@import "../../../../scss/variables.scss";

.rule {
  position: relative;
  &-input {
    height: 32px;
    background: $ruleInputBackground;
    width: 100%;
    box-sizing: border-box;
    min-width: 352px;
    border: 1px solid $canvasPrimaryBackground;
    color: $secondaryFontColor;
    line-height: 20px;
    font-size: $fontSizeMedium;
    padding-left: 10px;
    cursor: pointer;
    &:hover {
      background-color: #5e5e5e;
    }
    &-error {
      border-bottom: 1px solid #fa4d56;
    }
  }
}

.bool {
  position: relative;
  &-container {
    &-arrow {
      position: absolute;
      width: 1rem;
      height: 1rem;
      top: 34px;
      background-image: url(/static/media/arrow__down.dc4e20a5.svg);
      background-position: 50% 50%;
      background-size: 10px;
      background-repeat: no-repeat;
      color: #fff;
      filter: invert(100%);
      right: 10px;
    }
  }
  &-overlay {
    position: fixed;
    height: 100%;
    width: 383px;
    right: 0;
    top: 0;
    z-index: 1000;
  }
  &-dropdown {
    z-index: 1001;
    top: 18px;
    right: 0px;
    position: absolute;
    display: flex;
    flex-direction: column;
    min-width: 250px;
    border: 1px solid #313131;
    background-color: #555;
    color: #f3f3f3;
    font-size: 12px;
    font-weight: 300;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    &-option {
      display: flex;
      align-items: center;
      height: 32px;
      padding: 0px 8px 0px 24px;
      cursor: pointer;
      position: relative;
      &:hover {
        background-color: #5e5e5e;
      }
      &-selected-check {
        position: absolute;
        left: 0px;
        width: 12px;
        height: 12px;
        margin-left: 7px;
        flex: 0 0 auto;
        background-image: url(https://assets.website-files.com/5d7fdec85e3a80dddda38d90/5d83bcc9a185122e1566a7fe_checkmark-white.svg);
        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }
}
