.filters {
  &-header {
    font-size: 12px;
    font-weight: 300;
  }
  &-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    position: relative;
    max-height: 10rem;
  }
  &-add {
    width: 383px;
    z-index: 99;
    height: 100%;
    padding: 12px;
    padding-right: 22px;
    padding-top: 16px;
    background-color: #3d3d3d;
    position: fixed;
    top: 0px;
    right: 0px;
    &-header {
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
    }
    &-input {
      height: 32px;
      border: 1px solid #303030 !important;
      background-color: #555 !important;
      color: #f3f3f3;
      font-size: 12px;
      margin-top: 0px;
      padding-left: 12px;
      &:focus {
        box-shadow: none;
        border: 1px solid #303030;
      }
      &.error:focus {
        border: 1px solid #fa4d56;
      }
    }
  }
  &-value-error {
    color: #ff5b5b;
    font-size: 12px;
  }
  &-manage {
    &-container {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      position: relative;
      max-height: 10rem;
    }
  }
}

.clearfix {
  border-bottom: 1px solid #292929;
  margin: 0 -1.4rem 1rem -0.8rem;
}
