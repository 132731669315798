.pw-strength {
  &-wrap {
    display: flex;
    width: 100%;
    height: 3px;
    padding-right: 1px;
  }
  &-block {
    height: 100%;
    &-weak {
      width: 33.3%;
      background-color: #fb4b53;
    }
    &-medium {
      width: 66.6%;
      background-color: #fed23a;
    }
    &-strong {
      width: 100%;
      background-color: #3dbb61;
    }
  }
  &-text {
    &-label {
      display: flex;
      color: #bebebe;
      font-size: 0.75rem;
      font-weight: 300;
      letter-spacing: 0.32px;
      justify-content: space-between;
    }
    &-weak {
      color: #ff8389;
    }
    &-medium {
      color: #fed23a;
    }
    &-strong {
      color: #3dbb61;
    }
  }
  &-help {
    &-icon {
      display: inline-block;
      position: relative;
      justify-content: center;
    }
    &-text {
      display: flex;
      margin-left: 52px;
      flex-direction: column;
      padding: 1rem 1rem;
      background: #3d3d3d;
      position: absolute;
      top: -400%;
      left: 140%;
      min-width: 12rem;
      border-radius: 0px;
      border: 1px solid #8c8c8c;
      transition: opacity 0.5s;
    }
  }
}
.pw-strength-help-text > span > b {
  color: #fff;
}
.password-policy {
  justify-content: space-between;
  align-items: center;
  &-text {
    font-size: 0.75rem;
  }
}


//Password Helper for mobile portrait
@media only screen and (max-width: 479px) {
  .pw-strength-text-label {
    flex-wrap: wrap;
  }
  .pw-strength-help-icon {
    display: block;
    width: 100%;
  }
  .pw-strength-help-text {
    position: relative;
    top: 12px;
    margin-bottom: 16px;
    left: 0;
    margin-left: 0px;
  }
}

//Password Helper for mobile landscape
@media only screen and (min-width: 480px) and (max-width:767px) {
  .pw-strength-text-label {
    flex-wrap: wrap;
  }
  .pw-strength-help-icon {
    display: block;
    width: 100%;
  }
  .pw-strength-help-text {
    position: relative;
    top: 12px;
    margin-bottom: 16px;
    left: 0;
    margin-left: 0px;
  }
}

//Password Helper for tablets
@media only screen and (min-width: 768px) and (max-width:991px) {
  .pw-strength-text-label {
    flex-wrap: wrap;
  }
  .pw-strength-help-icon {
    display: block;
    width: 100%;
  }
  .pw-strength-help-text {
    position: relative;
    top: 12px;
    margin-bottom: 16px;
    left: 0;
    margin-left: 0px;
  }
}
