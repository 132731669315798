@import "../../scss/variables.scss";

.detail-action {
  position: relative;
  right: -20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 180px;
  height: 50px;
  padding-right: 16px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  cursor: default;
}

.detail-action > .dropdown-sort > .dropdown-list {
  overflow-y: hidden;
}

.detail-action > .dropdown-sort > .dropdown-toggle > .dropdown-main {
  padding-top: 10px;
  padding-bottom: 10px;
}

.detail-action > .dropdown-sort > .dropdown-toggle > .dropdown-toggle__icon {
  right: 8px;
}

.detail-action
  > .dropdown-sort
  > .dropdown-list.w--open
  > .dropdown-toggle__icon {
  right: 8px;
}

.version-btn__wrap > .dropdown-sort > .dropdown-list.w--open {
  background-color: transparent;
  box-shadow: none;
  border-bottom: none;
}

.version-actions__wrap > div > .dropdown-sort > .manage-version {
  background-color: transparent;
  box-shadow: none;
  border-bottom: none;
}

.version-actions__wrap
  > div
  > .dropdown-sort
  > .manage-version
  > .dropdown-icon {
  position: unset;
}

.version-actions__wrap
  > div
  > .dropdown-sort
  > .manage-version
  > .dropdown-main {
  width: 90%;
}

.version-btn__wrap > .dropdown-sort > .dropdown-list {
  overflow-y: hidden;
}

.version-btn__wrap > .dropdown-sort > .dropdown-toggle > .dropdown-icon {
  height: 40px;
  width: 40px;
  top: 0;
  right: 0;
  background-position: center !important;
}

.div-tooltip {
  margin-top: 8px;
}
.btn-details {
  width: 136px;
  margin-right: 16px;
  &-md {
    width: 150px;
    margin-right: 16px;
  }
}
.version-btn__wrap > .dropdown-sort > .dropdown-list.w--open > .dropdown-icon {
  background-position: center !important;
  height: 40px;
  width: 40px;
  top: 0;
  right: 0;
}

.runbook-settings__text {
  position: relative;
  margin-right: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.details {
  &-wrap {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 100%;
    margin-top: 24px;
    padding-left: 16px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  &__block-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  &-block {
    width: 50%;
    height: auto;
    max-width: 540px;
  }
}

.meta-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-top: 6px;
  padding-bottom: 6px;
}

.meta-text {
  margin-left: 5%;
  padding-right: 10%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  opacity: 0.9;
  color: #f3f3f3;
  font-size: $fontSizeMediumLarge;
  font-weight: 300;
  letter-spacing: 0.16px;
}

.meta-title {
  max-width: 140px;
  min-width: 110px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  opacity: 0.8;
  color: #bebebe;
  font-size: $fontSizeMediumLarge;
  font-weight: 300;
  letter-spacing: 0.16px;
}

.version {
  &-wrap {
    margin-top: 64px;
  }

  &-title {
    padding-left: 16px;
  }

  &-actions__wrap {
    display: flex;
    margin-top: 10px;
    height: 2.5rem;
    justify-content: space-between;
    align-items: center;
  }

  &-dropdown {
    width: 248px;
    height: 100%;
    margin-right: 16px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  &-btn__wrap {
    height: 100%;
    display: flex;
  }
}

.runbook-details-tabs {
  margin-top: 44px;
  min-height: 320px;
}

.runbook-details-tabs-content {
  margin-top: -10px;
}

.content-document {
  background: #252525;
  // overflow-x: scroll;
  overflow-y: scroll;
  max-height: 400px;
  padding: 16px;
  margin-bottom: 32px;
}

.full-height {
  height: 100%;
}

.run-btn-disabled {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  min-height: 0rem;
  flex: 0 0 auto;
  border: #6f6f6f;
  transition: all 100ms ease;
  color: #fff;
  font-weight: 300;
  padding-left: 16px;
  background: #6f6f6f;
  position: relative;
  opacity: 0.5;
}
