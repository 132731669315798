@import "src/scss/variables.scss";

$text-color: #f3f3f3;
.logo-header {
  position: fixed;
  z-index: 999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 56px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #171717;
  background-color: #282828;
}

h2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: $text-color;
  font-size: $fontSizeHeadingLarge;
  line-height: 36px;
  font-weight: 300;
}
.h2-margin {
  margin-bottom: 1rem;
}
.Neurops-logo {
  width: 30px;
  height: 30px;
}

.onboarding {
  &-wrap__flex {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: visible;
    width: 100vw;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 5px;
    padding-right: 32px;
    padding-left: 32px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  &-form {
    position: relative;
    left: auto;
    right: 0%;
    bottom: auto;
    display: flex;
    padding: 32px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    border-right: 1px solid #171717;
    background-color: #282828;
  }
  &-header-wrap {
    align-self: baseline;
    display: block;
    width: 100%;
    margin-bottom: 24px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    text-align: left;
  }
  &-card {
    position: relative;
    width: 400px;
    padding: 0px;
    background-color: transparent;
  }
  &-panel {
    margin-bottom: 2.5rem;
    padding: 1.5rem 2rem 2rem;
    background-color: #282828;
  }
}

.instructions {
  &__left {
    position: relative;
    top: -3rem;
    width: 60%;
    padding: 2rem;
  }
  &__right {
    position: sticky;
  }
  &__title {
    margin-bottom: 2rem;
  }
}
.account-info {
  &__wrap {
    margin-bottom: 1rem;
  }
  &__last {
    margin-bottom: 2rem;
  }
}
.submit-btn {
  height: 3rem;
  margin-top: 2rem;
  width: 25rem;
}

.inline {
  &-link {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 26px;
    padding-right: 8px;
    padding-left: 8px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #353535;
    -webkit-transition: all 90ms ease;
    transition: all 90ms ease;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    color: $text-color;
    font-size: $fontSizeMedium;
    line-height: 18px;
    text-decoration: none;
  }
  &-link:hover {
    background-color: #3d3d3d;
    cursor: pointer;
  }
  &-auto {
    width: 290px;
    max-width: 100%;
  }
}
.label-name {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-weight: 300;
  letter-spacing: 0.32px;
}
.btn-download {
  margin-right: 1rem;
  height: 2.5rem;
  width: 12rem;
  padding: 10px 0px 0px 13px;
  cursor: pointer;
  background-color: #6f6f6f;
  color: #fff;
  background-position: 90% 50%;
  background-size: 12px;
  background-repeat: no-repeat;
}

.text {
  &-field {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
  }
  &-input {
    width: 25rem;
    margin-bottom: 0px;
    border-color: transparent transparent #8c8c8c;
    border-radius: 0px;
    background-color: #3d3d3d;
    box-shadow: inset 0 0 0 2px transparent;
    -webkit-transition: all 70ms ease;
    transition: all 70ms ease;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    color: $text-color;
    font-weight: 300;
  }
}
.form-item {
  margin-bottom: 1rem;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  display: flex;
  align-items: flex-start;
}

.page-title {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 1.875rem;
  line-height: 2.675rem;
  font-weight: 400;
  letter-spacing: 0.32px;
}
.list {
  &-row {
    font-size: 0.95rem;
    line-height: 1.25rem;
    margin-bottom: 1rem;
    display: flex;
  }
  &-image {
    margin-bottom: 1.25rem;
    padding-left: 1.5rem;
  }
}
img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}
.step-number {
  font-size: $fontSizeMedium;
  text-align: center;
  border-radius: 100%;
  background-color: #505050;
  flex: 0 0 auto;
  display: flex;
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 8px;
  flex-direction: column;
}

.input {
  &-readonly {
    width: 90%;
    border: none;
    font-size: inherit;
    color: rgb(92, 168, 255);
    background: none;
    cursor: pointer;
  }
  &-feedback {
    color: #ff8389;
    margin-top: 0.25rem;
    font-size: 13px;
  }
  &-error {
    border-color: #fa4d56;
  }
}

.main-content {
  height: 100vh;
  overflow-y: auto;
}

.form-footer {
  right: 0%;
  bottom: 0%;
  display: -webkit-flex;
  display: -ms-flexbox;
  width: 100%;
  height: 3.5rem;
  display: inline-flex;
  flex: 0 0 auto;
  margin-top: 1rem;
}

.get-started-breadcrumb {
  padding-left: 4rem;
  margin-top: 6%;
  padding-bottom: 4%;
}

.input-eye-icon {
  z-index: 1;
  cursor: pointer;
  margin-left: -2rem;
  margin-right: 10px;
  fill: #8c8c8c;
  height: 18px;
  margin-top: 12px;
}
