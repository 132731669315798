.gs {
  &-container {
    height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
  &-title {
    padding: 2rem 2rem 2rem 4rem;
    color: #fff;
    font-size: 1.875rem;
    font-weight: 400;
    letter-spacing: 0.32px;
    margin-bottom: 0.5rem;
  }
  &-body {
    overflow-y: auto;
  }
  &-panel {
    padding: 0 4rem 4rem;
    display: flex;
    justify-content: space-between;
    overflow-y: auto;
    &-inner {
      padding: 1.5rem 2rem 2rem;
      background-color: #282828;
      height: fit-content;
    }
    &-left {
      display: flex;
      flex-direction: column;
      width: 63%;
      margin-right: 4rem;
    }
    &-right {
      width: 45%;
    }
  }
}

.instruction {
  &-link {
    color: #59cdff;
    &:hover {
      text-decoration: underline;
    }
  }
  &-higlight-code {
    border: 1px solid #3a3939;
    border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    padding: 5px;
    position: relative;
    top: 15px;
    background-color: #3a3939;
    font-size: 14px;
  }
}
