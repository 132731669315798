@import "../../../scss/variables.scss";

.btn {
  &-with-icon {
    position: absolute;
    right: 0.5rem;
    width: 1rem;
    height: 1rem;
    top: calc(50% - 5px);
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    background-size: 10px;
    background-repeat: no-repeat;
    &-small {
      background-size: 10px;
    }
    &-small-medium {
      background-size: 10px;
      right: 0.2rem;
    }
    &-medium {
      background-size: 12px;
    }
    &-large {
      background-size: 30px;
      width: 2rem;
      height: 2rem;
    }
  }

  &-inner-wrap {
    display: flex !important;
  }

  &-secondary {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border: 2px solid $btnSecondaryBorderColor;
    height: 100%;
    min-height: 0rem;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    background-color: $btnSecondaryBackground;
    -webkit-transition: all 100ms ease;
    transition: all 100ms ease;
    color: $btnSecondaryFontColor;
    font-weight: 300;
    width: 50%;
    padding-left: 5px;
    position: relative;
    cursor: pointer;

    &:hover {
      background: $btnSecondaryBackgroundHover;
      border: 2px solid $btnSecondaryBackgroundHover;
    }

    &:active {
      border: 2px solid $btnSecondaryActiveFontColor;
      background-color: $btnSecondaryActiveBackground;
    }

    &:focus {
      border: 2px solid $btnSecondaryFocusBorderColor;
      box-shadow: inset 0 0 0 1px #171717;
    }
  }

  &-primary {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 100%;
    min-height: 0rem;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border: 2px solid $primaryAccentColor;
    -webkit-transition: all 100ms ease;
    transition: all 100ms ease;
    color: $btnPrimaryFontColor;
    font-weight: 300;
    width: 50%;
    padding-left: 16px;
    background: $primaryAccentColor;
    cursor: pointer;
    position: relative;

    &:focus {
      border: 2px solid #f3f3f3;
      box-shadow: inset 0 0 0 1px #171717;
      background: $primaryAccentColorAlt;
    }

    &:active {
      border: 2px solid #f3f3f3;
      background: "green";
    }

    &:hover {
      background-color: $primaryAccentColorHover;
    }
  }
  &-link {
    align-items: center;
    box-sizing: border-box;
    height: 100%;
    min-height: 0rem;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    height: 24px;
    border: 2px solid transparent;
    -webkit-transition: all 100ms ease;
    transition: all 100ms ease;
    color: #5ca8ff;
    font-weight: 300;
    padding-left: 5px;
    background: #ffffff0d;
    cursor: pointer;
    position: relative;

    &:hover {
      background-color: #3d3d3d;
    }
  }

  &-warning {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 100%;
    min-height: 0rem;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border: 2px solid $warnBackground;
    -webkit-transition: all 100ms ease;
    transition: all 100ms ease;
    color: $warnFontColor;
    font-weight: 300;
    width: 50%;
    padding-left: 16px;
    background: $warnBackground;
    cursor: pointer;
    position: relative;

    &:focus {
      border: 2px solid #f3f3f3;
      box-shadow: inset 0 0 0 1px #171717;
    }

    &:active {
      border: 2px solid #f3f3f3;
      background: "green";
    }

    &:hover {
      background-color: $warnHoverBackground;
    }
  }
  &-disabled {
    background-color: #6f6f6f;
    color: #c1c1c1;
    border: none;
    cursor: not-allowed;
    &:hover {
      background: #6f6f6f;
      border: 0px;
    }
  }
}
