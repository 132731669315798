.twilio {
  &-container {
    text-overflow: ellipsis;
    background-color: #545454;
    color: #f3f3f3;
    height: 1.8rem;
    font-size: 0.75rem;
    padding-left: 10px;
    padding-right: 40px;
    border-top: 1px solid #303030;
    border-left: 1px solid #303030;
    border-right: 1px solid #303030;
  }

  &-input-error {
    border-width: 1px;
    border-style: solid;
    border-bottom-color: #fa4d56 !important;
  }
}

.normal-input-bottom {
  border-bottom: 1px solid #303030;
}
