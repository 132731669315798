#runbook-delete-confirm-modal {
  .modal-dialog {
    max-width: 768px;
    height: 311px;
  }
  .modal-content {
    background-color: #f3f3f3;
  }
  .modal-body {
    margin-bottom: 48px;
    p {
      font-size: $fontSizeLarge;
      font-weight: 300;
      color: #171717;
      letter-spacing: 0.16px;
      line-height: 20px;
    }
    #runbook-confirm-title {
      display: block;
      font-size: $fontSizeLarge;
      line-height: 1.42857143;
      color: #333333;
      vertical-align: middle;
      border: 1px solid #cccccc;
      width: 100%;
      height: 2.5rem;
      margin-bottom: 0px;
      padding: 0px 1rem;
      border-style: none none solid;
      border-width: 1px 0px;
      border-color: transparent transparent #8c8c8c;
      background-color: #ffffff;
      box-shadow: inset 0 0 0 2px transparent;
      transition: all 70ms ease;
    }
  }
  .modal-form-item {
    .label-delete {
      margin-top: 20px;
      margin-bottom: 8px;
      color: #171717;
      font-size: $fontSizeLarge;
      font-weight: 600;
    }
    span {
      color: #dc222b;
    }
  }
  .modal-footer {
    button {
      &.confirm-delete {
        background-color: #dc222b;
      }
    }
  }
}
