@import "@scss/variables.scss";

.invalid-json {
  visibility: hidden;
  color: $warnBackground;
}
.inline-accordion {
  height: 32px;
  align-items: center;
  background: #555;
  border: 1px solid #303030;
  &:hover {
    background: #616161;
  }
}
.helper-content {
  position: absolute;
  padding: 4px 10px;
  border-radius: 16px;
  width: auto;
  background: #f3f3f3c7;
  pointer-events: none;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  color: #282828;
  text-transform: uppercase;
}
.submit-disabled {
  background-color: #6300ff;
  border: 2px solid #6300ff;
  opacity: 0.5;
  cursor: not-allowed;
  &:hover {
    border: 2px solid #6f6f6f;
  }
}

.hide-error {
  visibility: visible;
}

.editor-container {
  margin-bottom: -3rem;
}

.JSON-viewer {
  &-container {
    padding-right: 0.5rem;
    background-color: #555;
    border-left: 1px solid #303030;
    border-right: 1px solid #303030;
    border-bottom: 1px solid #303030;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #616161;
    }
  }
  &-content {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(180deg, #f3f3f3 68%, #555 98%);
    border: none;
    background-clip: text;
    -webkit-background-clip: text;
    overflow: hidden;
    padding: 0.5rem;
    border: none;
  }
}
.jv-icon {
  padding: 4px;
  &-delete {
    margin-left: auto;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
  &-collapsed {
    transform: rotate(0deg);
    transition: transform 0.25s linear;
  }
  &-expanded {
    transform: rotate(180deg);
    transition: transform 0.25s linear;
  }
}

.JSON-viewer-container + .editor-gear-icon-wrap {
  top: 20px;
}
.mb-10 + .editor-gear-icon-wrap {
  top: 20px;
}
