.cf {
  display: flex;
  flex-direction: column;
  padding: 1rem 0.75rem;
  &-input {
    display: flex;
    height: 3.5rem;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 1rem;
    &-label {
      color: hsla(0, 0%, 95.3%, 0.7);
      font-size: 11px;
      font-weight: 300;
    }
  }
}

.cf-json {
  padding: 1rem 0.75rem;
}
